import React, { useState } from 'react'
import './LatestProductCarousel.css'
import ProductCard from '../ProductCard';
import ScrollContainer from 'react-indiana-drag-scroll'
import { MdOutlineArrowForwardIos } from 'react-icons/md';


const LatestProductCarousel = ({product}) => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
      setToggleState(index);
    };
  




  return (
   <div className="LatestProductCarousel">
     <div className="ButtonBarShop">
    <div className="ShopByTitle">
            <span className="ShopBySpan">LATEST PRODUCT</span>
            <MdOutlineArrowForwardIos
              color="#F65000"
              size={30}
              style={{ marginTop: "2px" }}
            />
          </div>
          <div className="ButtonBarShopButton">

    <div   onClick={() => toggleTab(1)}   className={toggleState === 1 ? "ButtonShopCardActive" : "ButtonShopCard"} >
    <label className="ButtonShopCardLabel" htmlFor="">Featured</label>
    <button className="ButtonShopCardButton"></button>
    </div>
    <div onClick={() => toggleTab(2)}   className={toggleState === 2 ? "ButtonShopCardActive" : "ButtonShopCard"}>
    <label style={{marginLeft: '8px'}} className="ButtonShopCardLabel" htmlFor="">Best Seller</label>
    <button className="ButtonShopCardButton"></button>
    </div>
    <div onClick={() => toggleTab(3)}   className={toggleState === 3 ? "ButtonShopCardActive" : "ButtonShopCard"}>
    <label style={{marginLeft: '9px'}} className="ButtonShopCardLabel" htmlFor="">Top Rated</label>
    <button className="ButtonShopCardButton"></button>
    </div>
    </div>

    </div>
    <div className="ShopProductCard">

<div className={toggleState === 1 ? "ShopProductCardOnClick" : "ShopProductCardOnClickNO"}>


    <ScrollContainer className="ShopProduct">
      {product?.map((item , id) => (
        <>
    <ProductCard item={item} id={id}/>
        </>
      ))}

    </ScrollContainer>
    </div>
    <div className={toggleState === 2 ? "ShopProductCardOnClick" : "ShopProductCardOnClickNO"}>


    <ScrollContainer className="ShopProduct">
    {product?.map((item , id) => (
        <>
    <ProductCard item={item} id={id}/>
        </>
      ))}
    </ScrollContainer>
    </div>
    <div className={toggleState === 3 ? "ShopProductCardOnClick" : "ShopProductCardOnClickNO"}>


    <ScrollContainer className="ShopProduct">
    {product?.map((item , id) => (
        <>
    <ProductCard item={item} id={id}/>
        </>
      ))}

    </ScrollContainer>
    </div>
</div>
   </div>
  )
}

export default LatestProductCarousel