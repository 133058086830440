import React from "react";
import { AiFillStar, AiOutlineEye, AiOutlineHeart, AiOutlineStar } from "react-icons/ai";
import { MdCompareArrows } from "react-icons/md";
import './ProductCardList.css';

const ProductCardList = ({item}) => {



  const wordLimit = 10; // Set your desired word limit here

  const truncateText = (text, limit) => {
    const words = text.split(' ');
    if (words.length <= limit) {
      return text;
    }
    const truncatedText = words.slice(0, limit).join(' ');
    return `${truncatedText}...Read More`;
  };


  return (
    <div>
      <div className="card-h44">
        <div className="card-heading54">
          <div className="left-h">
            <img src={item?.Image} alt="" />
          </div>
          <div className="right-h">
            <div className="right-texts">
              <h1 className="brand-n">{item?.brand}</h1>

              <h1 className="right-h1">
                {item?.name}
              </h1>
              <div className="stars-p">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <span
                  style={{
                    color: "black",
                    display: " flex",
                    alignItems: " center",
                    gap: "5px",
                  }}
                >
                  <AiOutlineStar /> (5)
                </span>
              </div>
              <div className="price-p">
                <h1 className="line-thr"> ₨ {item?.price}</h1>
                <h1 className="dis-p">₨ {item?.Dprice}</h1>
              </div>
              <p>
              {truncateText(item?.description || '', wordLimit)} 
              </p>
            </div>
            <div className="add-cart-b1">
            <button class="btn-hh shop-b">
        <span>Shop now</span>
      </button>
              <div className="card-svg1">
              <div class="tooltip facebook">
            <span class="tooltipText fb">Quick view</span>
            <AiOutlineEye />
        </div>
        <div class="tooltip twitter">
            <span  class="tooltipText fb">Add to Wishlist</span>
            <AiOutlineHeart/>
        </div>
        <div class="tooltip whatsapp">
            <span class="tooltipText fb">Compare</span>
            < MdCompareArrows className="comp-svg"/>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ProductCardList;
