
import { motion } from "framer-motion";

const Backdrop = ({ children, onClick , scrollTop }) => {
 

  return (
    <motion.div
    style={{marginTop: scrollTop}}
      className="backdrop"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
