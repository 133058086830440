import React from 'react'
import Image1 from '../Vlog Card/Assets/razer-wallpapers-page-hero-mobile.jpg'
import './VlogPlaylist.css'
import { BsFillPlayFill } from 'react-icons/bs'
import VlogPlaylistCard from './VlogPlaylistCard/VlogPlaylistCard'
import { Link } from 'react-router-dom'


const VlogPlaylist = () => {
  return (
    <>
   <div className="VlogPlaylist">
      <div className="VlogPlayListBody">
        <div className="VlogPlayListBodyLeft">
        <div className="VlogPlayListBodyLeftdiv">
          <div className="VlogPlayListBodyLeftdiv2">
            <Link to={'/VlogName'}>
          <button className='VlogPlayListBodyLeftdivButton'><BsFillPlayFill size={40}/></button>
            </Link>
          </div>
          <div className="VlogPlayListBodyLeftdiv3">
          <div className="VlogCradbg">
        <p className="VlogCardTitleDivP">SPORTS</p>
        </div>
        <p className='VlogCardTitleDivH1'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium debitis consectetur error numquam dolor deleniti ex neque provident repudiandae nam.</p>
    
          </div>
        </div>
          <img className='VlogPlayListBodyLeftImage' src={Image1} alt="" />
        </div>
        <div className="VlogPlayListBodyRight">
          <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>
          <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>
        </div>
      </div>
   </div>
   <div className="VlogPlaylistBottom">
    <div className="VlogPlaylistBottom2">

    <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>   <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
<VlogPlaylistCard/>
          </Link>
    </div>
   </div>
   </>

  )
}

export default VlogPlaylist