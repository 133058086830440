/* eslint-disable react-hooks/exhaustive-deps */
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../Firebase/Firebase";
import "./PRofile.css";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronRight, BiEdit } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { BsFillBasketFill } from "react-icons/bs";
import { toast } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import Currency from "react-currency-formatter";
import moment from "moment";

const Profile = () => {
  const [tab, setTab] = useState("tab1");
  const [addresstab, setAddresstab] = useState(false);
  const [userID] = useAuthState(auth);

  const [name, setName] = useState();

  const [phone, setPhone] = useState();
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [house, setHouse] = useState();

  const sendform = (e) => {
    if (
      !email ||
      !name ||
      !pincode ||
      !address ||
      !city ||
      !house ||
      !state ||
      !phone
    ) {
      toast.error("Please Fill All Fields");
    } else {
      e.preventDefault();

      addDoc(collection(db, "user", userID?.uid, "Address"), {
        name,
        phone,
        pincode,
        address,
        city,
        state,
        house,
        email,

        createdAt: serverTimestamp(),
      }).then((docRef) => {
        const washingtonRef = doc(db, "user", userID?.uid);
        updateDoc(washingtonRef, {
          defaultAddressid: docRef?.id,
        });

        setName("");
        setPhone("");
        setPincode("");
        setAddress("");
        setState("");
        setHouse("");

        setCity("");
        setEmail("");
      });
      setAddresstab(false);
      toast.success("Address add successfully");
    }
  };

  const [addresss, setAddresss] = useState();

  useEffect(() => {
    try {
      const q = collection(db, "user", userID?.uid, "Address");

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setAddresss(cities);
      });
    } catch {}
  }, [userID?.uid]);

  const [select, setSelect] = useState();

  const choose = (option) => {
    setSelect(option);

    toast.success("Please Fill All Fields");
    setTab("tab3");
  };

  useEffect(() => {
    if (select) {
      setName1(select.name);
      setEmail1(select.email);
      setPhone1(select.phone);
      setPincode1(select.pincode);
      setState1(select.state);
      setPincode1(select.pincode);
      setCity1(select.city);
      setAddress1(select.address);
      setHouse1(select.house);
    }
  }, [select]);

  const [name1, setName1] = useState();
  const [phone1, setPhone1] = useState();
  const [pincode1, setPincode1] = useState();
  const [address1, setAddress1] = useState();
  const [email1, setEmail1] = useState();
  const [city1, setCity1] = useState();
  const [state1, setState1] = useState();
  const [house1, setHouse1] = useState();

  const updateform = async (item) => {
    if (
      !email1 ||
      !name1 ||
      !pincode1 ||
      !address1 ||
      !city1 ||
      !state1 ||
      !house1 ||
      !phone1
    ) {
      toast.error("Please Fill All Fields");
    } else {
      const washingtonRef = doc(db, "user", userID?.uid, "Address", select?.id);
      await updateDoc(washingtonRef, {
        name: name1,
        phone: phone1,
        pincode: pincode1,
        address: address1,
        city: city1,
        state: state1,
        house: house1,
        email: email1,

        createdAt: serverTimestamp(),
      }).then(() => {
        setName1("");
        setPhone1("");
        setPincode1("");
        setAddress("");
        setState1("");
        setHouse1("");
        setCity1("");
        setEmail1("");
        setTab("tab1");
      });
      toast.success("Address updated successfully");
    }
  };

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
    toast.success("Logout  successfully");
  };

  let navigate = useNavigate();

  const [orderdetails, setOrderdetails] = useState();

  useEffect(() => {
    try {
      const q = collection(db, "user", userID?.uid, "Orders");

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setOrderdetails(cities);
      });
    } catch {}
  }, [userID?.uid]);

  console.log(orderdetails);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"white"} loading={isLoading} />
        </div>
      </>
    );
  }

  return (
    <>
      {/* 
 <div className="Profile">
   <div style={{ marginBottom: "40px" }} className="ForumHeader">
       <h1 style={{ textAlign: "center" }} className="ForumHeaderH1">
      Profile
      </h1>
  </div>
    <div className="ProfileTop">
     <div class="profilepic">
        <img
            class="profilepic__image"
            src="https://images.unsplash.com/photo-1510227272981-87123e259b17?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=3759e09a5b9fbe53088b23c615b6312e"
            width="150"
            height="150"
            alt="Profibild"
          />
          <div class="profilepic__content">
            <span class="profilepic__icon">
              <i class="fas fa-camera"></i>
            </span>
            <span class="profilepic__text">Edit Profile</span>
          </div>
        </div>
      </div>

    </div> */}

      <div className="main-d-profile">
        <div className="pro-d1">
          <div className="pro-d2">
            <div className="pro-c-1">
              {userID ? (
                <>
                  <div className="lh-profile">
                    <div className="check-uppr  upp-ch">
                      <Link to={"/"}>
                        <h1 className="check-h1">HomePage</h1>
                      </Link>
                      <BiChevronRight color="white" size={25} />
                      <h1 className="check-h2">My account</h1>
                    </div>
                    <div className="lh-d1-pro" onClick={() => setTab("tab1")}>
                      <h1 className="lh-pro-h1">Address</h1>
                      <AiOutlineUser />
                    </div>
                    <div className="lh-d1-pro1" onClick={() => setTab("tab2")}>
                      <h1 className="lh-pro-h1">Orders</h1>
                      <BsFillBasketFill />
                    </div>
                    <div onClick={handleSignOut} className="lh-d1-pro1">
                      <h1 className="lh-pro-h1">Logout</h1>
                      <HiOutlineLogout />
                    </div>
                  </div>
                  <div className="rh-profile">
                    <div>
                      {tab === "tab1" && (
                        <div>
                          <div className="rh-d-2">
                            <h1 className="add-details-rh">Address details</h1>
                            {addresss?.length === 0 ? (
                              <>
                                <BiEdit
                                  color="white"
                                  onClick={() => setAddresstab(!addresstab)}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="details-add-lf">
                            {addresstab ? (
                              <>
                                <form
                                  // onSubmit={handleSubmit}
                                  className="form-container"
                                >
                                  <div className="check-form-d1">
                                    <div className="form-group">
                                      <label className="check-label">
                                        Name
                                        <span style={{ color: "#f65000" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        className="input-check"
                                        type="text"
                                        id="name"
                                        name="name"
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                        value={name}
                                        // onChange={handleInputChange}
                                        required
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="check-label">
                                        Email{" "}
                                        <span style={{ color: "#f65000" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        className="input-check"
                                        type="email"
                                        id="email"
                                        name="email"
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        value={email}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="check-label">
                                      Contact No{" "}
                                      <span style={{ color: "#f65000" }}>
                                        *
                                      </span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      onChange={(e) => setPhone(e.target.value)}
                                      value={phone}
                                      required
                                    />
                                  </div>
                                  <div className="check-form-d1">
                                    <div className="form-group">
                                      <label className="check-label">
                                        Address{" "}
                                        <span style={{ color: "#f65000" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        className="input-check"
                                        type="text"
                                        onChange={(e) =>
                                          setAddress(e.target.value)
                                        }
                                        value={address}
                                        required
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="check-label">
                                        House / Flat No
                                        <span style={{ color: "#f65000" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        className="input-check"
                                        type="text"
                                        onChange={(e) =>
                                          setHouse(e.target.value)
                                        }
                                        value={house}
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="check-form-d1">
                                    <div className="form-group">
                                      <label className="check-label">
                                        Town/City{" "}
                                        <span style={{ color: "#f65000" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        className="input-check"
                                        type="text"
                                        onChange={(e) =>
                                          setCity(e.target.value)
                                        }
                                        value={city}
                                        required
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="check-label">
                                        Pincode{" "}
                                        <span style={{ color: "#f65000" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        className="input-check"
                                        type="text"
                                        onChange={(e) =>
                                          setPincode(e.target.value)
                                        }
                                        value={pincode}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="check-label">
                                      State{" "}
                                      <span style={{ color: "#f65000" }}>
                                        *
                                      </span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      onChange={(e) => setState(e.target.value)}
                                      value={state}
                                      required
                                    />
                                  </div>
                                  <button
                                    className="acc-btn-sa"
                                    onClick={sendform}
                                  >
                                    Save Address
                                  </button>
                                </form>
                              </>
                            ) : (
                              <>
                                {Array.isArray(addresss)
                                  ? addresss.map((item, key) => {
                                      return (
                                        <div className=" lh-cont-pro ">
                                          <div className="add-details-pro">
                                            <h1>{item.name}</h1>
                                            <h1>{item.email}</h1>
                                            <h1>{item.phone}</h1>
                                            <h1> {item.house},{item.address}</h1>

                                            <h1>{item.pincode}</h1>
                                            <h1>{item.state}</h1>
                                            <h1>{item.city}</h1>
                                          </div>
                                          <BiEdit
                                            color="white"
                                            onClick={() => choose(item)}
                                          />
                                        </div>
                                      );
                                    })
                                  : null}
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {tab === "tab3" && (
                        <>
                          <form
                            // onSubmit={handleSubmit}
                            className="form-container"
                          >
                            <div className="check-form-d1">
                              <div className="form-group">
                                <label className="check-label">
                                  Name
                                  <span style={{ color: "#f65000" }}>*</span>
                                </label>
                                <input
                                  className="input-check"
                                  type="text"
                                  id="name"
                                  name="name"
                                  onChange={(e) => setName1(e.target.value)}
                                  value={name1}
                                  // onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label className="check-label">
                                  Email{" "}
                                  <span style={{ color: "#f65000" }}>*</span>
                                </label>
                                <input
                                  className="input-check"
                                  type="email"
                                  id="email"
                                  name="email"
                                  onChange={(e) => setEmail1(e.target.value)}
                                  value={email1}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="check-label">
                                Contact No{" "}
                                <span style={{ color: "#f65000" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                onChange={(e) => setPhone1(e.target.value)}
                                value={phone1}
                                required
                              />
                            </div>
                            <div className="check-form-d1">
                              <div className="form-group">
                                <label className="check-label">
                                  Address{" "}
                                  <span style={{ color: "#f65000" }}>*</span>
                                </label>
                                <input
                                  className="input-check"
                                  type="text"
                                  onChange={(e) => setAddress1(e.target.value)}
                                  value={address1}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label className="check-label">
                                  House / Flat No
                                  <span style={{ color: "#f65000" }}>*</span>
                                </label>
                                <input
                                  className="input-check"
                                  type="text"
                                  onChange={(e) => setHouse1(e.target.value)}
                                  value={house1}
                                  required
                                />
                              </div>
                            </div>

                            <div className="check-form-d1">
                              <div className="form-group">
                                <label className="check-label">
                                  Town/City{" "}
                                  <span style={{ color: "#f65000" }}>*</span>
                                </label>
                                <input
                                  className="input-check"
                                  type="text"
                                  onChange={(e) => setCity1(e.target.value)}
                                  value={city1}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label className="check-label">
                                  Pincode{" "}
                                  <span style={{ color: "#f65000" }}>*</span>
                                </label>
                                <input
                                  className="input-check"
                                  type="text"
                                  onChange={(e) => setPincode1(e.target.value)}
                                  value={pincode1}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="check-label">
                                State{" "}
                                <span style={{ color: "#f65000" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                onChange={(e) => setState1(e.target.value)}
                                value={state1}
                                required
                              />
                            </div>
                            <button className="acc-btn-sa" onClick={updateform}>
                              Save Address
                            </button>
                          </form>
                        </>
                      )}

                      {tab === "tab2" && (
                        <>
                          <div className="rh-d-5">
                            <div className="rh-d-d2">
                              <h1 className="add-details-rh">Orders </h1>

                              <h1 className="my-or-dt">
                                My Order ({orderdetails.length})
                              </h1>
                            </div>
                            <div className="order-map-fun">
                              {Array.isArray(orderdetails)
                                ? orderdetails.map((item, key) => {
                                    return (
                                      <>
                                        <div className="order-box1">
                                          <div className="order-d-d2">
                                            <div className="order-d-dd1">
                                              <div className="order-d-dd2">
                                                <div className="order-d-dd3">
                                                  <h1>Order Placed</h1>
                                                  <h1>
                                                    {item?.createdAt
                                                      ? moment(
                                                          item.createdAt.toDate()
                                                        ).format("MMMM Do YYYY")
                                                      : null}
                                                  </h1>
                                                </div>
                                                <div className="order-d-dd3">
                                                  <h1>Total</h1>
                                                  <h1>
                                                    <Currency
                                                      quantity={
                                                        item.ProductPrice
                                                      }
                                                      currency="INR"
                                                    />
                                                  </h1>
                                                </div>
                                              </div>
                                              <div className="order-d-dd4">
                                                <h1>Order ID: {item.id}</h1>

                                                <h1
                                                  onClick={() =>
                                                    navigate("/ProductPrev", {
                                                      state: {
                                                        from: item.ProductName,
                                                      },
                                                    })
                                                  }
                                                  style={{
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Product Details
                                                </h1>
                                              </div>
                                            </div>
                                            <div></div>
                                          </div>

                                          <div className="order-box-d1">
                                            <div className="order-box-lh">
                                              <img
                                                className="order-box-img-1"
                                                src={item.ProductImage}
                                                alt=""
                                              />
                                              <div className="order-lh-prod">
                                                <h1 className="order-box-h1">
                                                  {item.ProductName}
                                                </h1>
                                                <h1 className="order-box-h2">
                                                  <Currency
                                                    quantity={item.ProductPrice}
                                                    currency="INR"
                                                  />
                                                </h1>
                                              </div>
                                            </div>

                                            <Link
                                              to={`/ProductPrev`}
                                              state={{ from: item.ProductName }}
                                              key={item.id}
                                            >
                                              <div className="order-box-rh">
                                                <button>Re-Order</button>
                                              </div>
                                            </Link>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="acc-no-use1">
                    <div className="check-uppr  upp-ch">
                      <Link to={"/"}>
                        <h1 className="check-h1">HomePage</h1>
                      </Link>
                      <BiChevronRight color="white" size={25} />
                      <h1 className="check-h2">My account</h1>
                    </div>
                    <button
                    style={{color:'black'}}
                      className="btn-sign-in-pro"
                      onClick={() => navigate("/Login")}
                    >
                      Sign in
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
