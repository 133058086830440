import React, { useEffect, useState } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineDelete,
  AiOutlineHeart,
} from "react-icons/ai";
import "./Wishlist.css";
import Image1 from "../../Assets/Products/product_16.jpg";
import { Link, useNavigate } from "react-router-dom";
import Currency from "react-currency-formatter";
import {
  GrClose
} from "react-icons/gr";
import Nav2 from "../Nav2";

const Wishlist = () => {
  const navigate = useNavigate();
  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  // saving data to local storage
  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const deleteBook = (id) => {
    const filteredBooks = wishlist.filter((element, index) => {
      return element.id !== id;
    });
    setWishlist(filteredBooks);
  };
  console.log(wishlist)

  return (
    <>
    
    

    <div className="Wishlist">


      <div className="WishlistTop">
        <p className="WishlistTopp1">Home</p>
        <p className="WishlistTopp11">/</p>
        <p className="WishlistTopp2">Wishlist</p>
      </div>

      {wishlist.length === 0 ? (
        <>
          <div className="WishlistTop2">
            <AiOutlineHeart size={50} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="WishlistTop3"
          >
            <h3 className="WishlistTop2h3">My Wishlist</h3>
            <h5 style={{ textAlign: "center" }} className="CartLefth2">
              Empty!
            </h5>
          </div>
        </>
      ) : (
        <>
          <div className="WishlistBody">
            <div className="WishlistBodyTable">
              <div className="TableContainer">
                <ul class="responsive-table">
                  <li class="table-header">
                    <div class="col col-1">Product Detail</div>
                    {/* <div class="col col-2">Quantity</div> */}
                    <div class="col col-3">Price {wishlist?.length} </div>
                    <div class="col col-4"></div>
                  </li>
                  {wishlist.map((item, id) => (
                    <>
                    <div className="wish-p-box1 mt-cart-1">

                   
                      <div className="wish-box-d2" >
                        <div className="cart-box-d1">
                          <img    src={item.image} alt="" />
                        </div>
                        <div className="cart-box-d2">
                          <div className="wish-mob-d1">
                            <h1> {item.productname}</h1>
                            <button
                              className="item-delete-cart"
                              onClick={() => deleteBook(item.id)}
                            >
                              <AiOutlineDelete color="black" size={20} />
                            </button>
                          </div>
                          <div className="wish-mob-d3">
                            <h1>Price:</h1>
                            <h1>
                              <Currency quantity={item.price} currency="INR" />
                            </h1>
                          </div>
                         
                        
                        </div>
                         </div>
                         <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={`/ProductPrev`}
                            state={{ from: item?.id }}
                            key={id}
                          >
                            <button className="wishlistaddtocart">
                              Preview
                            </button>
                          </Link>
                      </div>

                      <li class="table-row cart-p-box2">
                        <div class="col col-1" data-label=">Product Detail">
                          <div className="ProductDetailRow">
                            <div className="ProductDetailPart">
                              <AiOutlineDelete
                                onClick={() => deleteBook(item.id)}
                                size={30}
                                className="ProductDetailPartIcon"
                              />
                            </div>
                            <div className="ProductDetailPart1">
                              <img
                                className="ProductDetailRowImage"
                                src={item.image}
                                alt=""
                              />
                            </div>
                            <div className="ProductDetailPart2">
                              <h4 className="ProductDetailPart2h4 titleh4wdsfsdf">
                                {item.productname}
                              </h4>
                              <h5 className="ProductDetailPart2h4">
                                Category: {item.category}
                              </h5>

                              <h5 className="ProductDetailPart2h4">
                                Flavour : .........{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {/* <div class="col col-2" data-label="Quantity">
                 <input className="CartQuantityInput" value={'1'} type="number" name="" id="" />
                <div className="buttonInputField">
                  <span>
                  <button><AiOutlineArrowUp/></button>
                  </span>
                  <span><button><AiOutlineArrowDown/></button></span>
                </div>
                </div> */}
                        <div class="col col-3" data-label="Price">
                          <Currency quantity={item.price} currency="INR" />
                        </div>
                        <div class="col col-4">
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={`/ProductPrev`}
                            state={{ from: item?.id }}
                            key={id}
                          >
                            <button className="wishlistaddtocart">
                              Preview
                            </button>
                          </Link>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
        </>
  );
};

export default Wishlist;
