import React, { useEffect } from "react";
import "./Signin.css";
import { IoIosArrowForward } from "react-icons/io";
import { useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../Firebase/Firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { useAuthState } from "react-firebase-hooks/auth";

const SignIn = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const logInWithEmailAndPassword = () => {
    if (!email || !password) {
      toast.error("Please Fill All Fields");
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          navigate("/");
          toast.success("Sign in successfully");
        })
        .catch((error) => alert(error.message));
    }
  };

  const [email1, setEmail1] = useState("");
  const [password1, setPassword1] = useState("");
  const [name, setName] = useState("");



  const registerWithEmailAndPassword = () => {
    if (!email1 || !password1 || !name) {
      toast.error("Please Fill All Fields");
    } else {
      auth
        .createUserWithEmailAndPassword(email1, password1, name)
        .then((userCredentials) => {
          const user = userCredentials.user;
          setDoc(doc(db, "user", user.uid), {
            uid: user.uid,
            email1,
            name,

            createdAt: serverTimestamp(),
          }).then(() => {
            setEmail1("");
            setName("");
            setPassword1("");

            toast.success("Sign up successfully");
          });
          navigate('/')
        })
        .catch((error) => alert(error.message));
    }
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "user", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No Document Found");
          }
        });
      } else {
        console.log("no user");

        setUser();
      }
    });
  }, []);

  const [userID] = useAuthState(auth);



  return (
    <div className="SignIn">
      <div className="font77">
        <div className="hom-g4">
          <h1 className="homet-g">Home Page</h1>
          <span className="arrow-g">
            <IoIosArrowForward />
          </span>

          <h1 className="homeg-1">My account</h1>
        </div>
        <div className="fle-form">
          <div className="form-hd">
            <div>
              <div className="form-hg">
                <h1 className="font-io">LOGIN</h1>
                <div className="fornn">
                  <div className="fle-dd">
                    <label className="label-f">
                      Username or email address{" "}
                      <span style={{ color: " #f65000" }}>*</span>
                    </label>
                    <input
                      className="for-input"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your username or email address"
                      required
                    />
                  </div>
                  <div className="fle-dd1">
                    <label className="label-f">
                      Password <span style={{ color: " #f65000" }}>*</span>
                    </label>
                    <input
                      className="for-input"
                      type="Password"
                      name="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password *"
                      required
                    />
                  </div>
                  <div className="check-rem">
                    <div>
                      <input type="checkbox" />
                      <label for="vehicle1"> Remember me</label>
                    </div>

                    <p className="lost-pp">Lost your password?</p>
                  </div>
                  <div className="bth-g">
                    <button
                      disabled={!email || !password}
                      onClick={() => logInWithEmailAndPassword()}
                      className="btn-loginn"
                    >
                      LOG IN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-hd">
            <div>
              <div className="form-hg">
                <h1 className="font-io">Register</h1>
                <div className="fornn">
                  <div className="fle-dd">
                    <label className="label-f">
                      Name<span style={{ color: " #f65000" }}>*</span>
                    </label>
                    <input
                      className="for-input"
                      type="name"
                      name="name"
                      placeholder="Enter your Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="fle-dd">
                    <label className="label-f">
                      Email address<span style={{ color: " #f65000" }}>*</span>
                    </label>
                    <input
                      className="for-input"
                      type="email"
                      name="email"
                      placeholder="Enter your username or email address"
                      required
                      value={email1}
                      onChange={(e) => setEmail1(e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "25px" }} className="fle-dd">
                    <label className="label-f">
                      Password<span style={{ color: " #f65000" }}>*</span>
                    </label>
                    <input
                      className="for-input"
                      type="password"
                      name="password"
                      placeholder="Enter your Password"
                      required
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                  </div>
                  <div className="ju-8">
                    <p className="p-te5">
                      A password will be sent to your email address.
                    </p>
                  </div>
                  <div className="bth-g1">
                    <button
                      disabled={!name || !email1 || !password1}
                   

   onClick={() => {
    registerWithEmailAndPassword();
                    }}


                      className="btn-loginn1"
                    >
                      SIGN IN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
