import React, { useEffect, useState } from 'react'


const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return []; // Set a default value when there's no data in local storage
    }
  };
const Nav2 = () => {


    const [wishlist, setWishlist] = useState(getDatafromLS());

    // saving data to local storage
  
  
    useEffect(() => {
      localStorage.setItem("Wishlist", JSON.stringify(wishlist));
    }, [wishlist]);
    
  

  return (
    <div>

        <h1 style={{color:'red'}}>
            {wishlist?.length}
        </h1>
    </div>
  )
}

export default Nav2