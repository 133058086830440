import React from "react";
import "./Cart.css";
import "./Cart.scss";
import Image1 from "../../Assets/Products/product_16.jpg";
import {
  AiFillCloseCircle,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseItemQuantity,
  increaseItemQuantity,
  removeFromBasket,
} from "../Redux/basketSlice";

import Currency from "react-currency-formatter";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Firebase/Firebase";

const Cart = () => {
  // const items = useSelector(selectBasketItems);
  // const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);
  // useMemo(() => {
  //   const groupedItems = items.reduce((results, item) => {
  //     (results[item.id] = results[item.id] || []).push(item);
  //     return results;
  //   }, {});

  //   setGroupedItemsInBasket(groupedItems);
  // }, [items]);

  // const basketTotal = useSelector(selectBasketTotal);
  const [userID] = useAuthState(auth);

  const dispatch = useDispatch();

  const { cart, totalQuantity, basketTotal, totalPrice } = useSelector(
    (state) => state.allCart
  );

  return (
    <div className="Cart">
      {cart?.length === 0 ? (
        <>
          <div>
            <h2 style={{ textAlign: "center" }} className="CartLefth2">
              Shopping Cart
            </h2>
            <h5 style={{ textAlign: "center" }} className="CartLefth2">
              Empty!
            </h5>
          </div>
        </>
      ) : (
        <>
          <div className="CartContainer">
            <div className="CartLeft">
              <div className="TableContainer">
                <ul class="responsive-table">
                  <li class="table-header">
                    <div class="col col-1">Product Detail</div>
                    <div class="col col-2">Quantity</div>
                    <div class="col col-3">Price</div>
                    <div class="col col-4">Total</div>
                  </li>

                  {cart?.map((item) => (
                    <div>
                      <div className="cart-p-box1 mt-cart-1">
                        <div className="cart-box-d1">
                          <img src={item.image} alt="" />
                        </div>
                        <div className="cart-box-d2">
                          <div className="cart-mob-d1">
                            <h1>
                              {item.productname},{item.flavour}
                            </h1>
                            <button
                              className="item-delete-cart"
                              onClick={() =>
                                dispatch(
                                  removeFromBasket({
                                    id: item.id,
                                    flavour: item.flavour,
                                  })
                                )
                              }
                            >
                              <AiFillCloseCircle color="black" size={20} />
                            </button>
                          </div>
                          <div className="cart-mob-d3">
                            <h1>Price:</h1>
                            <h1>
                              <Currency quantity={item.price} currency="INR" />
                            </h1>
                          </div>
                          <div className="cart-mob-d3">
                            <h1>Quantity:</h1>
                            <div className="cart-mob-d5">
                              {item.quantity === 1 ? (
                                <>
                                  <button
                                  onClick={() =>
                                    dispatch(
                                      removeFromBasket({
                                        id: item.id,
                                        flavour: item.flavour,
                                      })
                                    )
                                  }
                                  >
                                    -
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                     onClick={() =>
                                      dispatch(
                                        decreaseItemQuantity({
                                          id: item.id,
                                          flavour: item.flavour,
                                        })
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                </>
                              )}

                              <h1>{item.quantity}</h1>

                              <button
                                  onClick={() =>
                                    dispatch(
                                      increaseItemQuantity({
                                        id: item.id,
                                        flavour: item.flavour,
                                      })
                                    )
                                  }
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="cart-mob-d4">
                            <h1>Total:</h1>
                            <h1>
                              <Currency
                                quantity={item.price * item.quantity}
                                currency="INR"
                              />
                            </h1>
                          </div>
                        </div>
                      </div>

                      <li class="table-row cart-p-box2">
                        <div class="col col-1" data-label=">Product Detail">
                          <div className="ProductDetailRow">
                            <div className="ProductDetailPart1">
                              <img
                                className="ProductDetailRowImage"
                                src={item.image}
                                alt=""
                              />
                            </div>
                            <div className="ProductDetailPart2">
                              <h4 className="ProductDetailPart2h4 titleh4wdsfsdf">
                                {item.productname}
                              </h4>
                              <h5 className="ProductDetailPart2h4">
                                {item.category}
                              </h5>

                              <h5 className="ProductDetailPart2h4">
                                {item.flavour}

                                {/* {item.map((item) => item.flavour).join(", ")} */}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="col col-2" data-label="Quantity">
                          <input
                            className="CartQuantityInput"
                            value={item.quantity}
                            type="number"
                            name=""
                            id=""
                          />
                          <div className="buttonInputField">
                            <span>
                              <button
                                onClick={() =>
                                  dispatch(
                                    increaseItemQuantity({
                                      id: item.id,
                                      flavour: item.flavour,
                                    })
                                  )
                                }
                              >
                                <AiOutlineArrowUp />
                              </button>
                            </span>

                            {item.quantity === 1 ? (
                              <>
                                <span>
                                  <button
                                     onClick={() =>
                                      dispatch(
                                        removeFromBasket({
                                          id: item.id,
                                          flavour: item.flavour,
                                        })
                                      )
                                    }
                                  >
                                    <AiOutlineArrowDown />
                                  </button>
                                </span>
                              </>
                            ) : (
                              <>
                                <span>
                                  <button
                                    onClick={() =>
                                      dispatch(
                                        decreaseItemQuantity({
                                          id: item.id,
                                          flavour: item.flavour,
                                        })
                                      )
                                    }
                                  >
                                    <AiOutlineArrowDown />
                                  </button>
                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        <div class="col col-3" data-label="Price">
                          <Currency quantity={item.price} currency="INR" />
                        </div>
                        <div class="col  data-total" data-label="Total">
                          <span>
                            <Currency
                              quantity={item.price * item.quantity}
                              currency="INR"
                            />
                          </span>
                          <span className="cancelbuttoncart">
                            {/* <button>
                              <AiFillCloseCircle
                                onClick={() =>
                                  dispatch(removeFromBasket(item.id))
                                }
                                size={17}
                              />
                            </button> */}

                            <button
                              className="item-delete-cart"
                              onClick={() =>
                                dispatch(
                                  removeFromBasket({
                                    id: item.id,
                                    flavour: item.flavour,
                                  })
                                )
                              }
                            >
                              <AiFillCloseCircle color="black" size={17} />
                            </button>
                          </span>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="CartRight">
              <h2 className="CartRighth2">Order Summary</h2>
              <div className="CardRightCard">
                <div className="CardRightTop">
                  <h4>ITEMS {totalQuantity}</h4>
                  <h4>
                    {" "}
                    <Currency quantity={totalPrice} currency="INR" />
                  </h4>
                </div>
                <div style={{ marginTop: "10px" }} className="CardRightTop">
                  <h4>SHIPPING</h4> <h4> FREE</h4>
                </div>
                <div
                  style={{ marginTop: "10px", paddingBottom: "10px" }}
                  className="CardRightTop"
                >
                  <h4>SUB TOTAL</h4>{" "}
                  <h4>
                    {" "}
                    <Currency quantity={totalPrice} currency="INR" />
                  </h4>
                </div>
                <hr />
                <div
                  style={{ marginTop: "10px", paddingBottom: "10px" }}
                  className="CardRightTop"
                >
                  <h4>TOTAL</h4>{" "}
                  <h4>
                    <Currency quantity={totalPrice} currency="INR" />
                  </h4>
                </div>
                <hr />

                {userID ? (
                  <>
                    <Link to={"/Checkout"}>
                      <button className="checkoutbutton">
                        CHECKOUT
                        <AiOutlineShoppingCart />
                      </button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={"/Checkout"}>
                      <button className="checkoutbutton">
                        GUEST CHECKOUT
                        <AiOutlineShoppingCart />
                      </button>
                    </Link>
                    <Link to={"/Login"}>
                      <button className="checkoutbutton">
                        MEMBER CHECKOUT
                        <AiOutlineShoppingCart />
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
