import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ProductCardList from "../../Mini-Components/ProductCardListView/ProductCardList";
import "./ProductList.css";
import { onSnapshot, query, where, collection } from "firebase/firestore";
import { db } from "../../../Firebase/Firebase";
import { BeatLoader } from "react-spinners";

const ProductList = () => {
  const [product, setProduct] = useState([]);
  const location = useLocation();
  const { item } = location.state;
  const [showAll, setShowAll] = useState(false);
  const [visibleProductCount, setVisibleProductCount] = useState(10);

  const items = item?.collection.split(",");

  useEffect(() => {
    const q = query(collection(db, "products"), where("category", "in", items));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const products = [];
      querySnapshot.forEach((doc) => {
        products.push(doc.data());
      });
      setProduct(products);
      setShowAll(false);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [item]);

  const handleShowAll = () => {
    if (visibleProductCount + 10 <= product.length) {
      setVisibleProductCount(visibleProductCount + 10);
    } else {
      setVisibleProductCount(product.length);
      setShowAll(true);
    }
  };

  const visibleProducts = showAll
    ? product
    : product.slice(0, visibleProductCount);

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"white"} loading={isLoading} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="ShopHead">
        <h1 className="ShopHeadH1">BUNDLE AND SAVE</h1>
        <h3 className="ShopHeadH3">
          {" "}
          <span className="ShopHeadSpan">20% OFF</span>ANY 3 PRODUCTS
        </h3>
      </div>
      <div className="pro-list-data">
        <div className="ProductList">
          <div className="ProductListMain">
            {/* <div className="ProductListMainLeft"></div> */}

            <div className="ProductListMainRight">
                {visibleProducts.map((item, id) => (
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={`/ProductPrev`}
                    state={{ from: item?.name }}
                    key={id}
                  >
                    <ProductCardList item={item} />
                  </Link>
                ))}
              {product.length > 10 && visibleProductCount < product.length && (
                <div className="btn-view-div">
                  <button className="btn-Load-more" onClick={handleShowAll}>
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
