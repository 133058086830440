import React, { useState } from 'react'
import './ProductCard2.css'
import { DynamicStar } from "react-dynamic-star";
import Pro1 from '../../Assets/CarouselProtienPNG/Protien1.png'
import { Link } from 'react-router-dom';

const ProductCard2 = ({item , id}) => {

    // eslint-disable-next-line no-unused-vars
    const [star, setStar] = useState({
        rating: 3.5,
        totalStars: 5,
        sharpness: 2.0,
        width: 15,
        height: 15,
        outlined: true,
        outlinedColor: "",
        fullStarColor: "#F65000",
        emptyStarColor: "transparent"
      });


  return (
 
<Link    style={{ textDecoration: "none", color: "black" }}
                to={`/ProductPrev`}
                state={{ from: item?.name }}>
    <div className="ProductHorizontalCard">
    <div className="ProductHorizontalCardLeft">
<img className="ProductHorizontalCardLeftimg" src={item?.Image} alt="" />
    </div>
    <div className="ProductHorizontalCardRight">
      <span className="ProductHorizontalCardRightSpan">{item?.name}</span>
      <div className="ProductHorizontalCardRightStar">

      <DynamicStar
    rating={parseFloat(star.rating)}
    width={parseFloat(star.width)}
    height={parseFloat(star.height)}
    outlined={star.outlinedColor ? star.outlinedColor : star.outlined}
    totalStars={star.totalStars}
    sharpnessStar={star.sharpness}
    fullStarColor={star.fullStarColor}
    emptyStarColor={star.emptyStarColor}
    />(5)
    </div>
    <span className="ProductHorizontalCardRightSpan1">₨ {item?.Dprice}</span>

      </div>
  </div>
    </Link>
  )
}

export default ProductCard2