/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Accordion1 from "./Accordion1";
import './Accordion.css'
import { useSpring, animated } from "react-spring";

function Accordion2() {

  const [open, setOpen] = useState(false);
  
  //toggle accordion function
  let toggleHandler = (e) => {
    //switch state
    setOpen(!open);
  };

  //title animation
  const titleAnimation = useSpring({
    from: {
      transform: "translateY(-30px)"
    },
    to: [{ transform: "translateY(15px)" }],
    config: { mass: 3, tension: 500, friction: 25 }
  });

  return (
      <div className="main-faq-m">
    <div className="main">


      <div className="accordion">
        <Accordion1

        
          title="How can I change something in my order?"
          text="First and foremost, you never want to go without security protection on your computer. Free Anti-Virus has very low detection rates. Give us a call and we will be happy to inform you of the latest security software we recommend and sell to all our clients for Spyware, Malware and Virus protection."
        />
        <Accordion1
          title="How long will my order take to be delivered?"
          text="First and foremost, you never want to go without security protection on your computer. Free Anti-Virus has very low detection rates. Give us a call and we will be happy to inform you of the latest security software we recommend and sell to all our clients for Spyware, Malware and Virus protection."
          />
        <Accordion1
           title="Can I return an item?"
           text="First and foremost, you never want to go without security protection on your computer. Free Anti-Virus has very low detection rates. Give us a call and we will be happy to inform you of the latest security software we recommend and sell to all our clients for Spyware, Malware and Virus protection."
          />
        <Accordion1
          title="How can I pay for my order?"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
        />
         <Accordion1
          title="Do I need an account and what are the benefits?"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
        />
      </div>
    </div>
          </div>
  );
}

export default Accordion2;
