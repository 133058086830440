/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Home.css";
import bg2 from "../../Assets/CarouselBackground/revslider_h1-2.jpg";
import p1 from "../../Assets/CarouselProtienPNG/revslider_img-2.png";
import bg3 from "../../Assets/CarouselBackground/revslider_h1-1.jpg";
import p2 from "../../Assets/CarouselProtienPNG/revslider_img-1.png";
import bg4 from "../../Assets/CarouselBackground/revslider_h2-2.jpg";
import bg42 from "../../Assets/CarouselBackground/revslider_shape.png";
import p3 from "../../Assets/CarouselProtienPNG/revslider_img-4.png";

import { MdOutlineArrowForwardIos } from "react-icons/md";

import LatestProductCarousel from "../Mini-Components/LatestProductCarousel/LatestProductCarousel";
import ProductCard2 from "../Mini-Components/ProductCard2";
import ReviewCard from "../Mini-Components/ReviewCard";
import ScrollContainer from "react-indiana-drag-scroll";
import BrandCard from "../Mini-Components/BrandCard";
import ForumCard from "../Mini-Components/ForumCard/ForumCard";
import { auth, db } from "../../Firebase/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Goal, Popular } from "../Shop/Category";
import { BeatLoader } from 'react-spinners'; // Import BeatLoader from react-spinners


const Home = () => {
  const [day1, setDay1] = useState();
  const [hours1, sethours1] = useState();
  const [minutes1, setminutes1] = useState();
  const [seconds1, setseconds1] = useState();

  function makeTimer() {
    var endtime = new Date(getTime);
    var endTime = Date.parse(endtime) / 1000;
    var Now = new Date();
    var now = Date.parse(Now) / 1000;
    var timeLeft = endTime - now;
    var days = Math.floor(timeLeft / 86400);
    var hours = Math.floor((timeLeft - days * 86400) / 3600);
    var minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    var seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < "10") {
      hours = "0" + hours;
    }
    if (minutes < "10") {
      minutes = "0" + minutes;
    }
    if (seconds < "10") {
      seconds = "0" + seconds;
    }

    setDay1(days);
    sethours1(hours);
    setminutes1(minutes);
    setseconds1(seconds);
  }

  // const [date, setDate] =useState()
  // eslint-disable-next-line no-unused-vars
  const [getTime, setgetTime] = useState("2023-4-30 00:00:00");

  useEffect(() => {
    const interval = setInterval(() => {
      // getDocument()
      makeTimer();
    }, 100);
    return () => clearInterval(interval);
  });

  // const getDocument = async () => {
  //   try {

  //     const docRef = doc(db, `users/${id}/` , 'time/current')
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       setgetTime(docSnap.data())

  //     } else {
  //       return {};
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

const [product,setProduct] = useState()
const [discountProduct,setDiscountProduct] = useState()


  useEffect(() => {
    const q = query(collection(db, "products") , where("category" ,'==' , "Protein"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
          cities.push(doc.data());
      });
      setProduct(cities)
      setIsLoading(false)
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)))
    });
  
  return () => unsubscribe()
  }, [])


  const[review , setReview] = useState()

  useEffect(() => {
    const q = query(collection(db, "review"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
          cities.push(doc.data());
      });
      setReview(cities)
  

     
    });
  
  return () => unsubscribe()
  }, [])

  const navigate = useNavigate();

const [item,setIteam]=useState({collection:'Protein,Protein-Isolate,Gainer'});

const [loadings, setLoadings] = useState(true);



const [isLoading, setIsLoading] = useState(true);




if (isLoading) {
  return (
    <>
  
  <div className='loader-sp'>

<BeatLoader size={15} color={'white'} loading={isLoading} />
</div>



    </>
  );
}



  return (
    <>




      <div className="Home">
        <>
    
          <div className="swiper">
            <Swiper
              autoHeight={true}
              // speed={1500}
              // loop={true}
              effect="fade"
              spaceBetween={20}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation, Parallax]}
              className="mySwiper"
            >
              <SwiperSlide>
                <>
                  <img src={bg2} className="SwiperSliderImage" alt="" />
                  <img src={p1} className="SwiperSliderImageIcon" alt="" />
                  <p className="SliderProteinCartel1">
                    100% authentic products{" "}
                  </p>
                </>
              </SwiperSlide>
              <SwiperSlide>
                <img src={bg3} className="SwiperSliderImage" alt="" />
                <img src={p2} className="SwiperSliderImageIcon1" alt="" />
                <p className="SwiperSliderImageP2">SHOP WITH CONFIDENCE</p>
                <p className="SliderProteinCartel2">best quality, best price</p>
                <p className="SliderProteinCartel3">
                  members save an extra 10% off
                </p>
                {/* <button className="SliderProteinCartelButton">
                  <span className="SliderProteinCartelButtonSpan">
                    SHOP NOW
                  </span>
                </button> */}
              </SwiperSlide>
              <SwiperSlide>
                <img src={bg4} className="SwiperSliderImageRotate" alt="" />
                <img src={bg42} className="SwiperSliderImageRotate1" alt="" />
                <img src={p3} className="SwiperSliderImageIcon11" alt="" />
                <p className="SwiperSliderImageP22">YOUR NUTRITION FOREVER</p>
                <p className="SliderProteinCartel22">
                  builds <br />
                  strength
                </p>
                <p className="SliderProteinCartel32">
                  and enables muscle growth
                </p>
                {/* <button className="SliderProteinCartelButton1">
                  <span className="SliderProteinCartelButtonSpan">
                    SHOP NOW
                  </span>
                </button> */}
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="HomeShopByTitle">
            <span className="HomeShopBySpan">SHOP BY GOAL</span>
            <MdOutlineArrowForwardIos
              color="#F65000"
              size={30}
              style={{ marginTop: "2px" }}
            />
          </div>
          <div className="CardHome">
          {Goal.map((item)=>(

<div className="HomeShopByCard">

<div className="HomeShopByCardBody"
onClick={() => navigate("/ProductList", { state: {item} })} 
>
  <div className="HomeShopByCardBodyMid"></div>
  <img
    className="HomeShopByCardBodyMidImage"
    src={item.img}
    alt=""
  />
</div>
<span className="HomeShopByCardBodyMidSpan">{item.category}</span>
<span className="HomeShopByCardBodyMidSpan2">{item.description}</span>
</div>
          ))}

        
    
          </div>
          <div style={{ marginTop: "70px" }} className="HomeShopByTitle">
            <span className="HomeShopBySpan">POPULAR CATEGORIES</span>
            <MdOutlineArrowForwardIos
              color="#F65000"
              size={30}
              style={{ marginTop: "2px" }}
            />
          </div>

          <div className="ProductsHome">

{Popular.map((item)=>(

<div 
onClick={() => navigate("/ProductList", { state: {item} })}


className="CardCategory">
<img className="CardCategoryImage" src={item.img} alt="" />
<span className="CardCategorySpan">{item.category}</span>
</div>

))}

         

{/* 

            <div className="CardCategory">
              <img className="CardCategoryImage" src={CategoryPng1} alt="" />
              <span className="CardCategorySpan">BCAA & EAAS</span>
            </div>{" "}
            <div className="CardCategory">
              <img className="CardCategoryImage" src={CategoryPng2} alt="" />
              <span className="CardCategorySpan">Pre Workout</span>
            </div>{" "}
            <div className="CardCategory">
              <img className="CardCategoryImage" src={CategoryPng3} alt="" />
              <span className="CardCategorySpan">Fat Burners</span>
            </div>{" "}
            <div className="CardCategory">
              <img className="CardCategoryImage" src={CategoryPng4} alt="" />
              <span className="CardCategorySpan">Vitamin & Health</span>
            </div>
            <div className="CardCategory">
              <img className="CardCategoryImage" src={CategoryPng5} alt="" />
              <span className="CardCategorySpan">Creatine</span>
            </div> */}
          </div>
          <LatestProductCarousel product={product} />
          <div className="ProductSliderOff">
            <div className="ProductSliderOffLeft">
              <span className="ProductSliderOffLeft1">THIS WEEK DEALS</span>
              <span className="ProductSliderOffLeft2">UP TO 50% OFF</span>
              <div className="ProductSliderOffLEftLEft">
                <span className="ProductSliderOffLeft3">
                  LIMITED QUANTITIES. HURRY UP!
                </span>
                <div id="timer" className=" timers">
                  <div id="days" className="day">
                    <span style={{ marginLeft: "7px" }}> {day1}</span>
                  </div>
                  <div
                    id="hours"
                    className="align-items-center flex-column d-flex justify-content-center"
                  >
                    <span>{hours1}</span>
                  </div>
                  <div
                    id="minutes"
                    className="align-items-center flex-column d-flex justify-content-center"
                  >
                    <span style={{ marginLeft: "7px" }}>{minutes1} </span>
                  </div>
                  <div
                    id="seconds"
                    className="align-items-center flex-column d-flex justify-content-center"
                  >
                    <span style={{ marginLeft: "5px" }}>{seconds1}</span>
                  </div>
                </div>
                <span className="ProductSliderOffLeft4">View All Deals</span>
              </div>
            </div>
            <div className="ProductSliderOffRight">
              <>
              {discountProduct?.map((item, id) => (
                
                <ProductCard2 item={item} id={id}  />
                ))}
                </>
          
            </div>
          </div>
          {/* <div className="HomeVideoSLide">
            <div className="HomeVideoSLideLeft">
              <p className="HomeVideoSLideLeftP">
                MOST FAVOURITE <br />
                SUPPLEMENT BRAND
              </p>
              <p className="HomeVideoSLideLeftP2">
                Protein Cartel is the leading supplements and health store
                retailer in South Africa, situated across the country with 27+
                stores and an online store, stocking the latest international
                and local brands at unbeatable prices.
              </p>
              <div className="HomeVideoSLideLeftNumber">
                <div className="HomeVideoSLideLeftNumber1">
                  <p className="HomeVideoSLideLeftNumber1p">4,458 k</p>
                  <span className="HomeVideoSLideLeftNumber1span">
                    BOTTLES SOLD
                  </span>
                </div>
                <div className="HomeVideoSLideLeftNumber1">
                  <p className="HomeVideoSLideLeftNumber1p">1,159 k</p>
                  <span className="HomeVideoSLideLeftNumber1span">
                    CUSTOMER SERVED
                  </span>
                </div>
              </div>
            </div>
            <div className="HomeVideoSLideRight">
              <iframe
                 className="HomeVideoSLideRightiframe"
                src="https://www.youtube.com/embed/wm3lp9bC5Kc"
                title="Why Your Parents Don't Support You | The Alchemist"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div> */}
          {/* <div className="HomeCustomerReviewsHead">
            <span className="HomeCustomerReviewsHeadSpan1"></span>
            <span className="HomeCustomerReviewsHeadSpan2">
              OUR HAPPY CUSTOMERS{" "}
              <MdOutlineArrowForwardIos
                className="HomeCustomerReviewsHeadSpan2Icon"
                color="#F65000"
                size={30}
              />
            </span>
          </div> */}
          {/* <div>
            <ScrollContainer className="HomeCustomerReviews">
            {review?.map((review , id) => (

              <ReviewCard review={review} id={id}/>
            ))}
            
            </ScrollContainer>
          </div> */}
          {/* <div style={{ marginTop: "70px" }} className="HomeShopByTitle1">
            <span className="HomeShopBySpan">SHOP BY BRAND</span>
            <MdOutlineArrowForwardIos
              color="#F65000"
              size={30}
              style={{ marginTop: "2px" }}
            />
          </div> */}
          {/* <div className="ShopByBrandHome">
            <BrandCard />
            <BrandCard />
            <BrandCard />
            <BrandCard />
            <BrandCard />
            <BrandCard />
          </div>
          <div className="HomeForum">
            <ScrollContainer className="HomeForumScroll">
              <ForumCard />
              <ForumCard />
              <ForumCard />
            </ScrollContainer>
          </div> */}
        </>
      </div>
    </>
  );
};

export default Home;
