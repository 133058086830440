import React from 'react'
import './CategoryCard.css'
import IMG from '../../../Assets/Category/h1_img4.jpg'
import { Link } from 'react-router-dom'

const CategoryCard = ({item}) => {
  return (
   <div className="CategoryCard">
    <img className="CategoryCardImage" src={IMG} alt="" />
    <ul className="CategoryCardUL">
      <Link style={{color: 'white'}} to={'/ProductList'}>
        {/* {category.map((item)=>(
        <li className="CategoryCardLI">WHEY PROTEIN</li>

        ))} */}

        {item?.id}
      </Link>
   
    </ul>
   
    <span className="CategoryCardSpan">View All</span>
  
   </div>
  )
}

export default CategoryCard