import React from 'react'
import { FiSend } from "react-icons/fi";
import { BsBoxSeam } from "react-icons/bs";
import { FiHeadphones } from "react-icons/fi";
import '../Home/Home.css'
import Nav2 from '../Nav2';


const TopBar = () => {
  return (
    <div style={{marginLeft: '10px' , marginRight: '10px'}} className="SkinHomeTop">
   
    <div className="SkinHomeTop1">
      <FiSend color="black" className="SkinHomeTop1Icon" />
      <span className="SkinHomeTop1Span">
        Free shipping on orders over 
      </span>
    </div>
    <div className="SkinHomeTop11">
      <BsBoxSeam color="black" className="SkinHomeTop1Icon" />
      <span className="SkinHomeTop1Span">
        Easy returns on all orders
      </span>
    </div>
    <div className="SkinHomeTop1">
      <FiHeadphones color="black" className="SkinHomeTop1Icon" />
      <span className="SkinHomeTop1Span">24/7 Customer support</span>
    </div>
  </div>
  )
}

export default TopBar