import React, { useState } from 'react'
import './ProductCard.css'
import { DynamicStar } from "react-dynamic-star";
import { AiOutlineEye, AiOutlineHeart } from 'react-icons/ai';
import useModal from './Modal/useModal';
import { AnimatePresence } from "framer-motion";
import Modal from '../Mini-Components/Modal/Modal/index';
import { Link } from 'react-router-dom';

const ProductCard = ({item , id}) => {

  // eslint-disable-next-line no-unused-vars
  const [star, setStar] = useState({
    rating: 3.5,
    totalStars: 5,
    sharpness: 2.0,
    width: 15,
    height: 15,
    outlined: true,
    outlinedColor: "",
    fullStarColor: "#F65000",
    emptyStarColor: "transparent"
  });


  const [scrollTop , setScrollTop] = useState('0px')
  const { modalOpen, close, open } = useModal();

  const OnClickScrollFunction = (Scroll) => {
    open()
    setScrollTop(Scroll + 'px')
    document.body.style.overflow = 'hidden';
  }

  const wordLimit = 5; // Set your desired word limit here

  const truncateText = (text, limit) => {
    const words = text.split(' ');
    if (words.length <= limit) {
      return text;
    }
    const truncatedText = words.slice(0, limit).join(' ');
    return `${truncatedText}...Read More`;
  };


  return (
    <>
         <ModalContainer>
            {modalOpen && (
              <Modal modalOpen={modalOpen} scrollTop={scrollTop}  handleClose={close} item={item} tag={'Fitness'} />
            )}
          </ModalContainer>
          <Link    style={{ textDecoration: "none", color: "black" }}
                to={`/ProductPrev`}
                state={{ from: item?.name }}>
          
    <div className="ProductCard">
      <div className="IconButton">
        <div className="IconButtonCard">
          <AiOutlineHeart className="IconButtonCardIcon"/>
        </div>
        <div onClick={()=> OnClickScrollFunction(window.pageYOffset)} className="IconButtonCard">
          <AiOutlineEye className="IconButtonCardIcon"/>
        </div>
      </div>
      <img className="ProductCardImage" src={item?.Image} alt="" />
      <span className="ProductCardSpan">
      {truncateText(item?.brand || '', 2)} 
        
      </span>
      <span style={{color: 'black'}} className="ProductCardSpan1">
        
      {truncateText(item?.name || '', wordLimit)} 
        
 
        </span>
      <div className="ProductCardDiv">

      <DynamicStar
        rating={parseFloat(star.rating)}
        width={parseFloat(star.width)}
        height={parseFloat(star.height)}
        outlined={star.outlinedColor ? star.outlinedColor : star.outlined}
        totalStars={star.totalStars}
        sharpnessStar={star.sharpness}
        fullStarColor={star.fullStarColor}
        emptyStarColor={star.emptyStarColor}
        />(5)
        </div>
        <span className="ProductCardSpan2">₨ {item?.Dprice}</span>
   <button className="ProductCardButton"><span>ADD TO CART</span></button>
    </div>
    </Link>
    </>

  )
}


const ModalContainer = ({ children }) => (
    // Enables the animation of components that have been removed from the tree
    <AnimatePresence
      initial={false}
      exitBeforeEnter={true}
    >
      {children}
    </AnimatePresence>
  );



export default ProductCard