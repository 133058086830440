/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer2.css";
import {
  AiFillFacebook,
  AiFillYoutube,
  AiOutlineClockCircle,
  AiOutlineInstagram,
  AiOutlineMessage,
} from "react-icons/ai";
import {
  FaCcAmazonPay,
  FaCcDiscover,
  FaCcMastercard,
  FaCcPaypal,
  FaCcVisa,
} from "react-icons/fa";
import "./Footer.css";

import { BsTelephone, BsTwitter } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";

const Footer2 = () => {
  return (
    <>
      <div style={{ marginTop: "200px" }}>
        <div className="font-f">
          {/* <div className="border-con">
            <div className="contact-ff border-r">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">PHONE</h1>
                <h1 className="hot-t1">+(91) 98764 31599</h1>
              </div>
            </div>

            <div className="contact-f  ">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">Office ADDRESS</h1>
                <h1 className="hot-t1">
                  <span>
                    Plot No.56 Near Jain Sweets, Jay Vishwa bharti Colony
                    Jawahar Nagar, Aurangabad , 431001
                  </span>
                </h1>
              </div>
            </div>

            <div className="contact-f  border-r2">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">Warehouse Address:</h1>
                <h1 className="hot-t1">
                  <span>House No.167, Khuda Alisher Chandigarh,160001</span>
                </h1>
              </div>
            </div>
            <div className="contact-f2">
              <CiMail className="phone-svgff" />
              <div>
                <h1 className="hot-t">EMAIL US</h1>
                <h1 className="hot-t1">proteincartel.in@gmail.com</h1>
              </div>
            </div>
          </div> */}
{/* <div className="f-f--1">


          <div className="f-f2 ">
            <div className="contact-ff border-r">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">PHONE</h1>
                <h1 className="hot-t1">+(91) 98764 31599</h1>
              </div>
            </div>
            <div className="contact-ff border-r">
            <BsTelephone className="phone-svgff" />

              <div>
                <h1 className="hot-t">Office ADDRESS</h1>
                <p>
                  Plot No.56 Near Jain Sweets, Jay Vishwa bharti Colony Jawahar
                  Nagar, Aurangabad , 431001.
                </p>
              </div>
            </div>

            <div className="contact-ff border-r">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">Warehouse Address:</h1>
                <p>House No.167, Khuda Alisher Chandigarh,160001.</p>
              </div>
            </div>
            <div className="contact-ff border-r">
              <CiMail className="phone-svgff" />
              <div>
                <h1 className="hot-t">EMAIL US</h1>
                <p>proteincartel.in@gmail.com</p>
              </div>
            </div>
          </div>
  
          </div> */}
          {/* <div  className="f-f2">
<div className="contact-ff border-r">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">PHONE</h1>
                <h1 className="hot-t1">+(91) 98764 31599</h1>
              </div>
            </div>
            <div className="contact-f  ">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">Office ADDRESS</h1>
                <h1 className="hot-t1">
                <span>
                  
                Plot No.56 Near Jain Sweets, Jay

                  Vishwa bharti Colony Jawahar Nagar, Aurangabad , 431001 
                </span>

                </h1>
              </div>
            </div>
            <div className="contact-f  border-r2">
              <BsTelephone className="phone-svgff" />
              <div>
                <h1 className="hot-t">Warehouse Address:</h1>
                <h1 className="hot-t1">
                <span>House No.167, Khuda Alisher Chandigarh,160001</span>

                </h1>
              </div>
            </div>
            <div className="contact-f2">
              <CiMail className="phone-svgff" />
              <div>
                <h1 className="hot-t">EMAIL US</h1>
                <h1 className="hot-t1">proteincartel.in@gmail.com</h1>
              </div>
            </div>
</div> */}

          <footer class="footer">
            <div class="footer__addr">
              <h1 class="footer__logo">Protein Cartel</h1>

              <p className="footer-p">
                Protein Cartel is the leading supplements and health store
                retailer in India.
              </p>
              <div className="have-a">
                <div className="fle-sv-h">
                  <AiOutlineMessage className="svg-mess" />
                  <div>
                    <h1 className="h1-q">HAVE A QUESTION?</h1>
                    <h1 className="h-ask">Ask Our Experts</h1>
                  </div>
                </div>
              </div>
            </div>

            {/* <ul class="footer__nav">
            <li class="nav__item">
              <h2 class="nav__title">Quick Links</h2>

              <ul class="nav__ul">
                <li className="hovli">
                  <a href="">Search</a>
                </li>

                <li>
                  <a href="">Become a Reseller</a>
                </li>

                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Terms of Service</a>
                </li>
              </ul>
            </li>

            <li class="nav__item">
              <h2 class="nav__title">Customer Service</h2>

              <ul class="nav__ul">
                <li>
                  <a href="">Help Center</a>
                </li>

                <li>
                  <a href="">My Account</a>
                </li>

                <li>
                  <a href="#">Track My Order</a>
                </li>
                <li>
                  <a href="#"> Return Policy</a>
                </li>
                <li>
                  <a href="#"> Gift Cards</a>
                </li>
              </ul>
            </li>
            <li class="nav__item">
              <h2 class="nav__title">About Us</h2>

              <ul class="nav__ul">
                <li>
                  <a href="">Company Info</a>
                </li>

                <li>
                  <a href="">Press Releases</a>
                </li>

                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Reviews</a>
                </li>
                <li>
                  <a href="#">Investor Relations</a>
                </li>
              </ul>
            </li>
          </ul> */}
            <hr />
            <div className="social-mm">
              <div class="social-buttons">
                <a
                  href="#"
                  class="social-button social-button--facebook"
                  aria-label="Facebook"
                >
                  <AiFillFacebook className="iconFooter" color="white" />
                </a>
                <a
                  href="#"
                  class="social-button social-button--linkedin"
                  aria-label="LinkedIn"
                >
                  <BsTwitter className="iconFooter" color="white" />
                </a>
                <a
                  href="#"
                  class="social-button social-button--snapchat"
                  aria-label="Snapchat"
                >
                  <AiOutlineInstagram className="iconFooter" color="white" />
                </a>

                <a
                  href="#"
                  class="social-button social-button--codepen"
                  aria-label="CodePen"
                >
                  <AiFillYoutube className="iconFooter" color="white" />
                </a>
              </div>

              <div className="ul-1">
                <ul className="nav-usocial">
                  <Link style={{ color: "white" }} to={"/Terms&Conditions"}>
                    <li>Terms & Conditions </li>
                  </Link>
                  <span style={{ cursor: "context-menu" }}>|</span>
                  <Link style={{ color: "white" }} to={"/Privacy-Policy"}>
                    <li>Privacy Policy </li>{" "}
                  </Link>
                  <span style={{ cursor: "context-menu" }}>|</span>
                  <Link style={{ color: "white" }} to={"/Refund&Cancellation"}>
                    <li>Cancellation & Refund Policy</li>
                  </Link>
                  <span style={{ cursor: "context-menu" }}>|</span>
                  <Link
                    style={{ color: "white" }}
                    to={"/Shipping&DeliveryPolicy"}
                  >
                    <li>Shipping & Delivery Policy</li>
                  </Link>
                  <span style={{ cursor: "context-menu" }}>|</span>
                  <Link style={{ color: "white" }} to={"/ContactUs"}>
                    <li>Contact us</li>
                  </Link>
                  {/* <span style={{ cursor: "context-menu" }}>|</span>
                <li>Help</li> <span style={{ cursor: "context-menu" }}>|</span>
                <li> Site map</li> */}
                </ul>
              </div>
            </div>
            <hr />

            <div class="legal">
              <p>© 2023 PROTEIN CARTEL All Right Reserved</p>

              <div className="svgs-cards1">
                <FaCcVisa className="svg-card2" />
                <FaCcMastercard className="svg-card2" />
                <FaCcAmazonPay className="svg-card2" />
                <FaCcDiscover className="svg-card2" />
                <FaCcPaypal className="svg-card2" />
              </div>

              {/* <div class="legal__links">
      <span>Made with <span class="heart">♥</span> remotely from Anywhere</span>
    </div> */}
            </div>
          </footer>
        </div>
      </div>
      <article id="wrap">
        <article id="lightings">
          <section id="one">
            <section id="two">
              <section id="three">
                <section id="four">
                  <section id="five"></section>
                </section>
              </section>
            </section>
          </section>
        </article>
      </article>
    </>
  );
};

export default Footer2;
