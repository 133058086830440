import React from 'react'
import './ForumPost.css'

const ForumPost2 = () => {
  return (
   <div className="ForumPost">
<div className="ForumPostHeader">
    <div className="ForumPostHeaderLeft">
        <img className='ForumPostHeaderLeftImg' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2C_xadF4WT19MkU5PpYyU8njyMgMIuttwXQ&usqp=CAU" alt="" />
    </div>
    <div className="ForumPostHeaderRight">
        <h5 className="ForumPostHeaderRighth5">Gurkirat Singh</h5>
        <h6 className="ForumPostHeaderRighth6">10 mins ago</h6>
    </div>
</div>
<div style={{marginBottom: '30px'}} className="ForumPostBody">
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis qui et ea, deleniti eligendi animi inventore atque in quaerat ad blanditiis quisquam, repellendus aliquam error labore aspernatur hic totam nobis enim deserunt fugit voluptate, eius consectetur! Sequi, voluptates. Reiciendis rem nostrum officiis modi labore excepturi repellendus inventore ut deleniti quas fugit, magni, hic enim optio quibusdam sunt, tempore cupiditate autem aspernatur laborum sed dolores eius iure veritatis. Quis, consequatur quisquam! Sapiente id totam a error recusandae accusantium, facere aliquam obcaecati.</p>
</div>
   </div>
  )
}

export default ForumPost2