import React from "react";
import "./Contactus.css";
import { BiMap } from "react-icons/bi";
import { IoCall } from "react-icons/io5";

const   Contactus = () => {
  return (
    <div className="bg-conn1">
      <h1 className="con-text">Contact us</h1>
      <div className="row-card-con">
        <div className="card-rr1">
          <div className="card-rr2">
            <div className="icon-mc">
              <span>
                <BiMap className="ico-mc2" />
              </span>
            </div>
            <div className="add-c1">
              <span>Office Address:</span>
            </div>
            <div className="add-c2">
              <span
       
              >
                Plot No.56 Near Jain Sweets, Jay

Vishwa bharti Colony Jawahar Nagar, Aurangabad , 431001 
              </span>
            </div>
            <div className="add-c1">
              <span>Warehouse Address:</span>
            </div>
            <div className="add-c2">
              <span>House No.167, Khuda Alisher Chandigarh,160001</span>
            </div>
          </div>
        </div>

        <div className="card-rr1">
          <div className="card-rr2">
            <div className="icon-mc">
              <span>
                <IoCall className="ico-mc2" />
              </span>
            </div>
            <div className="add-c1">
              <span>Phone Number:</span>
            </div>
            <div className="add-c2">
              <span>+(91) 98764 31599</span>
            </div>
          </div>
        </div>
        <div className="card-rr1">
          <div className="card-rr2">
            <div className="icon-mc">
              <span>
                <BiMap className="ico-mc2" />
              </span>
            </div>
            <div className="add-c1">
              <span>Mail Address:</span>
            </div>
            <div className="add-c2">
              <span>proteincartel.in@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
