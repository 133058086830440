import React, { useState } from "react";
import "./LatestProductCarousel.css";
import ProductCard from "../ProductCard";
import ScrollContainer from "react-indiana-drag-scroll";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import CategoryCard from "../CategoryCard/CategoryCard";
import { Link, useNavigate } from "react-router-dom";
import IMG from "../../../Assets/Category/h1_img4.jpg";

const ProductCarouselWithCategory = ({ product, category, head }) => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const navigate = useNavigate();

  return (
    <div className="LatestProductCarousel">
      <div className="ButtonBarShop">
        <div className="ShopByTitle">
          <span className="ShopBySpan">{head}</span>

          <MdOutlineArrowForwardIos
            color="#F65000"
            size={30}
            style={{ marginTop: "2px" }}
          />
        </div>
        <div className="ButtonBarShopButton">
          <div
            onClick={() => toggleTab(1)}
            className={
              toggleState === 1 ? "ButtonShopCardActive" : "ButtonShopCard"
            }
          >
            <label className="ButtonShopCardLabel" htmlFor="">
              Featured
            </label>
            <button className="ButtonShopCardButton"></button>
          </div>
          <div
            onClick={() => toggleTab(2)}
            className={
              toggleState === 2 ? "ButtonShopCardActive" : "ButtonShopCard"
            }
          >
            <label
              style={{ marginLeft: "8px" }}
              className="ButtonShopCardLabel"
              htmlFor=""
            >
              Best Seller
            </label>
            <button className="ButtonShopCardButton"></button>
          </div>
          <div
            onClick={() => toggleTab(3)}
            className={
              toggleState === 3 ? "ButtonShopCardActive" : "ButtonShopCard"
            }
          >
            <label
              style={{ marginLeft: "9px" }}
              className="ButtonShopCardLabel"
              htmlFor=""
            >
              Top Rated
            </label>
            <button className="ButtonShopCardButton"></button>
          </div>
        </div>
      </div>
      <div className="ShopProductCard">
        <div
          className={
            toggleState === 1
              ? "ShopProductCardOnClick"
              : "ShopProductCardOnClickNO"
          }
        >
          {/* {category.map((item)=>(
     <CategoryCard  item={item} />
          ))}
      */}

          <div className="CategoryCard">
            <img className="CategoryCardImage" src={IMG} alt="" />
            <ul className="CategoryCardUL">
              {category.map((item) => (
                <span
                className={'CategoryCardSpan'}
                  onClick={() => navigate("/ProductList", { state: { item } })}
                  style={{ color: "white" }}
                >
                  <li
                    style={{ marginTop: "2px", textTransform: "uppercase" }}
                    className="CategoryCardLI"
                  >
                    {item.category}
                  </li>
                </span>
              ))}
            </ul>

        
          </div>

          <ScrollContainer className="ShopProduct">
            {product?.map((item, id) => (
              <>
                <ProductCard item={item} id={id} />
              </>
            ))}
          </ScrollContainer>
        </div>
        <div
          className={
            toggleState === 2
              ? "ShopProductCardOnClick"
              : "ShopProductCardOnClickNO"
          }
        >
          <CategoryCard />
          <ScrollContainer className="ShopProduct">
            {product?.map((item, id) => (
              <>
                <ProductCard item={item} id={id} />
              </>
            ))}
          </ScrollContainer>
        </div>
        <div
          className={
            toggleState === 3
              ? "ShopProductCardOnClick"
              : "ShopProductCardOnClickNO"
          }
        >
          <CategoryCard />
          <ScrollContainer className="ShopProduct">
            {product?.map((item, id) => (
              <>
                <ProductCard item={item} id={id} />
              </>
            ))}
          </ScrollContainer>
        </div>
      </div>
    </div>
  );
};

export default ProductCarouselWithCategory;
