import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
import Shop from "./Components/Shop/Shop";
import Workouts from "./Components/Workouts/Workouts";
import Forum from "./Components/Forum/Forum";
import Navbar from "./Components/Navbar";
import TopBar from "./Components/CommonCompo/TopBar";
import Footer2 from "./Components/Footer/Footer2";
import ProductList from "./Components/Shop/ProductList/ProductList";
import ProductPrev from "./Components/Shop/ShopCompo/ProductPrev/ProductPrev";

import SignIn from "./Components/SignIn/SignIn";
import FAQs from "./Components/FAQs/FAQs";
import BlogName from "./Components/Articles/Blog/BlogName";
import Blog from "./Components/Articles/Blog/Blog";
import Vlog from "./Components/Articles/Vlog";
import ForumName from "./Components/Forum/ForumName/ForumName";
import VlogName from "./Components/Articles/VlogName/VlogName";
import Wishlist from "./Components/Wishlist/Wishlist";
import Cart from "./Components/Cart/Cart";
import Checkout from "./Components/Cart/Checkout/Checkout";
import { Toaster } from "react-hot-toast";
import Profile from "./Components/Profile/Profile";
import Privacy from "./Components/Term&Conditions/Privacy";
import Refund from "./Components/Term&Conditions/Refund";
import Term from "./Components/Term&Conditions/Term";
import Contactus from "./Components/Contactus/Contactus";
import Shipping from "./Components/Term&Conditions/Shipping";
import ScrollToTop from "./Components/ScrollToTop";
import store from "./Store";
import { Provider } from "react-redux";
import Test from "./Components/Test";
import Search1 from "./Components/Search/Search1";


function App() {
  return (
    <div className="App">
            <Provider store={store}>


 
      <Router basename="/">
        <Navbar />
        <TopBar />
        <Toaster />
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Test" element={<Test/>} />

          <Route path="/Shop" element={<Shop />} />
          <Route path="/Login" element={<SignIn />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/ProductPrev" element={<ProductPrev />} />
          <Route path="/Vlog" element={<Vlog />} />
          <Route path="/VlogName" element={<VlogName />} />
          <Route path="/Workouts" element={<Workouts />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/Forum" element={<Forum />} />
          <Route path="/ForumName" element={<ForumName />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/Wishlist" element={<Wishlist />} />
          <Route path="/BlogName" element={<BlogName />} />
          <Route path="/ProductList" element={<ProductList />} />
          <Route path="/Privacy-Policy" element={<Privacy />} />
          <Route path="/Refund&Cancellation" element={<Refund />} />
          <Route path="/Terms&Conditions" element={<Term />} />
          <Route path="/Shipping&DeliveryPolicy" element={<Shipping />} />
          <Route path="/ContactUs" element={<Contactus />} />
          <Route path="/Search" element={<Search1 />} />

        </Routes>

        <Footer2 />
      </Router>
                 </Provider>
    </div>
  );
}

export default App;
