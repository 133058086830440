import React, { useEffect, useState } from "react";
import "./ProductPrev.css";
import { IoIosArrowForward } from "react-icons/io";
import {
  AiFillStar,
  AiOutlineHeart,
  AiOutlineStar,
  AiOutlineTwitter,
} from "react-icons/ai";
import { MdCompareArrows } from "react-icons/md";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { ImFacebook } from "react-icons/im";

import ProductPrevCarousel from "./ProductPrevCarousel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../../../Firebase/Firebase";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { addToBasket } from "../../../Redux/basketSlice";
import { toast } from "react-hot-toast";

const getDatafromLS = () => {
  const data = localStorage.getItem("Wishlist");
  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};

const ProductPrev = () => {
  const location = useLocation();
  const { from } = location.state;

  const [item, setItem] = useState();

  useEffect(() => {
    getDoc(doc(db, "products", from)).then((docSnap) => {
      if (docSnap.exists()) {
        setItem(docSnap.data());
        setIsLoading(false);
      } else {
        console.log("No such document!");
      }
    });
  }, [from,item]);

  console.log(from)

  const navigate = useNavigate();

  const [counter, setCounter] = useState(1);
  const increment = () => {
    setCounter(counter + 1);
  };
  const decrement = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    } else {
      setCounter(counter);
    }
  };

  let text1 = window.location.href;
  let text2 = "?fehaguhfd79ydg73hr";
  let result = text1.concat(text2);

  const splitArr = result.split("?");

  const flavour = ["Chocolate", "Banana"];
  const [selectedflavour, setSelectedFlavour] = useState();

  const wordLimit = 40; // Set your desired word limit here

  const truncateText = (text, limit) => {
    const words = text.split(" ");
    if (words.length <= limit) {
      return text;
    }
    const truncatedText = words.slice(0, limit).join(" ");
    return `${truncatedText}`;
  };

  const [readmore, setReadmore] = useState(false);

  const dispatch = useDispatch();

  // const addItemToBasket = () => {
  //   const stringPrice = item?.Dprice;
  //   const stringActualPrice = item?.price;

  //   // Convert string prices to numbers
  //   const price = parseFloat(stringPrice);
  //   const actualprice = parseFloat(stringActualPrice);

  //   dispatch(
  //     addToBasket({
  //       id: item?.name,
  //       productname: item?.name,
  //       price: price, // Converted to a number
  //       actualprice: actualprice, // Converted to a number
  //       image: item?.Image,
  //       brand: item?.Brand,
  //       flavour: selectedflavour,
  //       category: item?.category,
  //     })
  //   );

  //   toast.success("Added to Bag");
  // };

  // const addItemToBasket = () => {
  //   const stringPrice = item?.Dprice;
  //   const stringActualPrice = item?.price;

  //   // Convert string prices to numbers
  //   const price = parseFloat(stringPrice);
  //   const actualprice = parseFloat(stringActualPrice);

  //   dispatch(
  //     addToBasket({
  //       id: item?.name,
  //       productname: item?.name,
  //       price: price, // Converted to a number
  //       actualprice: actualprice, // Converted to a number
  //       image: item?.Image,
  //       brand: item?.Brand,
  //       flavour: selectedflavour,
  //       category: item?.category,
  //         quantity: 1,

  //     })
  //   );

  //   toast.success("Added to Bag");
  // };
  const addItemToBasket = () => {
    const stringPrice = item?.Dprice;
    const stringActualPrice = item?.price;

    // Convert string prices to numbers
    const price = parseFloat(stringPrice);
    const actualprice = parseFloat(stringActualPrice);

    // Add 2 items to the basket
    for (let i = 0; i < counter; i++) {
      dispatch(
        addToBasket({
          id: item?.name,
          productname: item?.name,
          price: price, // Converted to a number
          actualprice: actualprice, // Converted to a number
          image: item?.Image,
          brand: item?.Brand,
          flavour: selectedflavour,
          category: item?.category,
          quantity: 1,
        })
      );
    }
    navigate("/Cart");
    toast.success("Added to Bag");
  };

  // const addItemToBasket = () => {
  //   // const stringPrice = item?.Dprice;
  //   // const stringActualPrice = item?.price;

  //   // // Convert string prices to numbers
  //   // const price = parseFloat(stringPrice);
  //   // const actualprice = parseFloat(stringActualPrice);

  //   // Add 2 items to the basket

  //     dispatch(
  //       addToBasket({
  //       id: item?.name,
  //         productname: item?.name,
  //         quantity: 1,

  //       })
  //     )

  //   // navigate('/Cart')
  //   toast.success("Added to Bag");
  // };

  const [Wishlist, setWishlist] = useState(getDatafromLS());

  // const handleWish = (e) => {
  //   const stringPrice = item?.Dprice;
  //   const stringActualPrice = item?.price;

  //   // Convert string prices to numbers
  //   const price = parseFloat(stringPrice);
  //   const actualprice = parseFloat(stringActualPrice);
  //   // creating an object
  //   let Wish = {
  //     id: item?.name,
  //     productname: item?.name,
  //     price: price, // Converted to a number
  //     actualprice: actualprice, // Converted to a number
  //     image: item?.Image,
  //     brand: item?.Brand,
  //     flavour: selectedflavour,
  //     category: item?.category,
  //   };
  //   setWishlist([...Wishlist, Wish]);
  //   toast.success("Add to Wishlist");
  // };


  const handleWish = (e) => {
    const stringPrice = item?.Dprice;
    const stringActualPrice = item?.price;
  
    // Convert string prices to numbers
    const price = parseFloat(stringPrice);
    const actualprice = parseFloat(stringActualPrice);
    
    // Creating an object
    const newWish = {
      id: item?.name,
      productname: item?.name,
      price: price, // Converted to a number
      actualprice: actualprice, // Converted to a number
      image: item?.Image,
      brand: item?.Brand,
      flavour: selectedflavour,
      category: item?.category,
    };
  
    // Check if an item with the same id already exists in the wishlist
    const isDuplicate = Wishlist.some(wish => wish.id === newWish.id);
  
    if (!isDuplicate) {
      // Add the new wish to the wishlist only if it's not a duplicate
      setWishlist([...Wishlist, newWish]);
      toast.success("Added to Wishlist");
    } else {
      toast.error("Item is already in the Wishlist");
    }
  };



  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(Wishlist));
  }, [Wishlist]);

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"white"} loading={isLoading} />
        </div>
      </>
    );
  }

  return (
    <div>
      <div>
        <div className="Pro-texts">
          <Link to={"/"}>
            <h1 className="h-text">Home Page</h1>
          </Link>
          <span className="arrow-span">
            <IoIosArrowForward />
          </span>
          <Link to={"/Shop"}>
            <h1 className="apsp-text">Shop</h1>
          </Link>

          <span className="arrow-span">
            <IoIosArrowForward />
          </span>

          <h1 className="nat-text">{item?.name}</h1>
        </div>
        <div className="carousel-product">
          <ProductPrevCarousel item={item?.name} image={item?.Image} />
          <div className="product-data1">
            <div>
              <h1 className="ske-55">
                <span className="in-s">IN STOCK</span>
              </h1>
            </div>
            <h1 className="carou-text">{item?.name}</h1>
            <div className="star-col">
              <div className="star-div7">
                <AiFillStar className="star-svg1" />
                <AiFillStar className="star-svg1" />
                <AiFillStar className="star-svg1" />

                <AiFillStar className="star-svg1" />

                <span
                  style={{
                    color: "white",
                  }}
                >
                  <AiOutlineStar />
                </span>
                <h1 className="review-t"> (5Reviews)</h1>
              </div>
              <div className="brand-texts">
                <h1 className="bran-text">Brands:</h1>
                <h1 className="bran-text2"> {item?.Brand}</h1>
              </div>
              <div className="brand-texts">
                <h1 className="bran-text">SKU:</h1>
                <h1 className="bran-text2">BE45VGRT</h1>
              </div>
            </div>
            <div className="price-p4">
              <h1 className="text-actualprice"> ₨ {item?.price}</h1>
              <h1 className="text-discountp">₨ {item?.Dprice}</h1>
            </div>
            <div className="para-cartt">
              <p className="p-para-cartt">
                {readmore ? (
                  <>{item?.description}</>
                ) : (
                  <>{truncateText(item?.description || "", wordLimit)}</>
                )}
                {item?.description.length > 250 && (
                  <button
                    onClick={() => setReadmore(!readmore)}
                    style={{
                      backgroundColor: "transparent",
                      border: "transparent",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    {readmore ? "..Close" : "..Read More"}
                  </button>
                )}
              </p>
            </div>
            <div className="sold-text">
              <h1 className="sold-t">Sold:</h1>
              <h1 className="solid-t1">31/62</h1>
            </div>
            <div class="progress">
              <div class="progress-value"></div>
            </div>
            {/* <div className="ava-div">
                <h1 className="ava-text">Available on backorder</h1>
            </div> */}
            <div>
              <h1 className="carou-text2">Flavour</h1>
              <div>
                {selectedflavour && (
                  <h1 className="selected-flv-h1">
                    Selected Flavour :{selectedflavour}
                  </h1>
                )}

                <div className="btn-data-fla">
                  {flavour.map((flavour) => (
                    <>
                      <button
                        className={
                          selectedflavour === flavour
                            ? "btn-fla-s1"
                            : "btn-fla-s"
                        }
                        onClick={() => setSelectedFlavour(flavour)}
                      >
                        {flavour}
                      </button>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="comtt">
              <div className="incdec-butt">
                <button className="btn-count" onClick={decrement}>
                  -
                </button>
                <h1 className="count-h">{counter}</h1>
                <button className="btn-count" onClick={increment}>
                  +
                </button>
              </div>
              <div className="btn-dd-add">
                {counter === 0 ? (
                  <>
                    <button className="add-cart-pre">
                      Select Quantity First
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => addItemToBasket(counter)}
                      className="add-cart-pre"
                    >
                      Add to Cart
                    </button>
                  </>
                )}

                <div className="tooltip-svgs1">
                  <div class="tooltip2 twitter" onClick={() => handleWish()}>
                    <span class="tooltipText2 fb1">Browse Wishlist</span>
                    <AiOutlineHeart className="font-svg1" />
                  </div>
                  <div class="tooltip2 whatsapp">
                    <span class="tooltipText2 fb1">Compare</span>
                    <MdCompareArrows className="font-svg1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-fg">
              <div className="floo">
                <h1 className="cat-text">Category:</h1>
                <h1 className="cat-text1">{item?.category}</h1>
              </div>
              <div className="floo">
                <h1 className="cat-text">Tag:</h1>
                <h1 className="cat-text1">{item?.category}</h1>
              </div>
            </div>

            <div className="social-i-cart">
              <h1 className="share-tt">Share:</h1>
              <div className="social-iconsisv">
                <ImFacebook className="fb-v" />
                <AiOutlineTwitter className="tw-v" />
                <FaLinkedinIn className="linkk-v" />
                <FaInstagram className="insta-v" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPrev;
