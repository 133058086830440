import React from 'react'
import './TrendingCard.css'
import Image1 from '../Vlog Card/Assets/razer-wallpapers-page-hero-mobile.jpg'


const TrendingCard = () => {
  return (
   <div className="TrendingCard">
    <img className='TrendingCardImage' src={Image1} alt="" />
    <div className="TrendingCardTrendingCard">
    <div className="TrendingCardTag">
        <p>SPORTS</p>
    </div>
    <div className="TrendingCardBody">
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis, odio earum libero repellat possimus quisquam voluptate voluptatem soluta.
            
        </p>
    </div>
    </div>
   </div>
  )
}

export default TrendingCard