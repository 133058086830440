import React from 'react'
import './FAQs.css'
import Accordion2 from "./Acccordion2";
import Accordion3 from "./Accordion3";
import { IoIosArrowForward } from "react-icons/io";

const FAQs = () => {
  return (
   <div className="FAQS">
      <div >
      <div className="bg-f">
        <div className="faq-h">
          <div className="faq-sup-img">
            <img
              className="img-faq"
              src={require("../../Assets/FAQS/Faq_img.png")}
              alt=""
            />
          </div>
          <div className="faq-text-hh">
            <h1 className="Faq-h1">Have a Question?</h1>
            <h1 className="faq-h2">
              Frequently asked <br /> questions
            </h1>
            <p className="faq-p1">
              Velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum. Sed ut perspiciatis unde
              omnis iste natus error sit voluptatem accu santium olorem que laud
              antium id est laborum.
            </p>
          </div>
        </div>
      </div>
      <div className="acco-r-t">
        <div className="gen-fd">
          <h1 className="faq-h3">GENERAL QUESTIONS <span className="arrow-spa"><IoIosArrowForward/></span></h1>
          <p className="faq-p2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore.
          </p>
        </div>
        <Accordion2 />
      </div>
      <div className="acco-r-t">
        <div className="gen-fd">
          <h1 className="faq-h3">ADVANCED QUESTIONS
          <span className="arrow-spa"><IoIosArrowForward/></span>
</h1>
          <p className="faq-p2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore.
          </p>
        </div>
        <Accordion3 />
      </div>
    </div>
   </div>
  )
}

export default FAQs