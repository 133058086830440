import React from 'react'
import './SearchBox.scss'

const Searchbox = () => {
  return (
   <div className="SearchBox">
<form className='searchform' onsubmit="event.preventDefault();" role="search">
  <label className='searchlabel' for="search">Search for stuff</label>
  <input className='searchinput' id="search" type="search" placeholder="Search..." autofocus required />
  <button className='searchbutton' type="submit">Go</button>    
</form>
   </div>
  )
}

export default Searchbox