import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";


const app = firebase.initializeApp({
    apiKey: "AIzaSyCuGxlwoMUt6_y6H3rt8on5JXVayJWOJ4M",
    authDomain: "proteincartel-b01b9.firebaseapp.com",
    projectId: "proteincartel-b01b9",
    storageBucket: "proteincartel-b01b9.appspot.com",
    messagingSenderId: "434237892560",
    appId: "1:434237892560:web:81c56f0dee7834fbaefbd5"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = app.auth()
export default app

