import React from "react";
import Searchbox from "./Components/Searchbox";
import "./Forum.css";
import {FiSend} from 'react-icons/fi'
import ForumPost from "./Components/ForumPost/ForumPost";

const Forum = () => {
  return (
    <div className="Forum">
      <div style={{ marginBottom: "40px" }} className="ForumHeader">
        <h1 className="ForumHeaderH1">FORUM!</h1>
      </div>
      <Searchbox />
      <div className="ForumBody">
        <h1 className="ForumBodyH1">Hello Gurkirat</h1>
        <p className="ForumBodyp">
          What's new with you? Would you like to share something with community?
          🤗
        </p>
        <div className="ForumCreatePost">
          <div className="ForumCreatePostBody">
          <h3 className="ForumCreatePostBodyh3">Create Post</h3>
          <input className="ForumCreatePostBodyinput" type="text" />
          <div className="ForumCreatePostBodydiv">
          <button className="ForumCreatePostBodyButton"><FiSend/>Publish</button>
          </div>
          </div>
        </div>
          <ForumPost/>
          <ForumPost/>
          <ForumPost/>
          <ForumPost/>

      </div>
    </div>
  );
};

export default Forum;
