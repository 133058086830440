import React from "react";

import {  IoIosArrowForward } from "react-icons/io";
import ForumCard1 from "../../Mini-Components/ForumComponents/ForumCard1";

import "./Blog.css";
import './Take.css'

const Blog = () => {
  return (
    <div className="Forum">
       <div style={{ marginBottom: "40px" }} className="ForumHeader">
        <h1 style={{textAlign : 'center'}} className="ForumHeaderH1">Blogs!</h1>
      </div>
      <div class="wrapperForum">
        <article className="articleForum">
         <ForumCard1/>
         <ForumCard1/>
         <ForumCard1/>
         <ForumCard1/>

        </article>
        <aside className="asideForum">
        <div className="left-h-t">
            <div>
              <div className="take-fl-l">
                <h1 className="take-lh1">Search</h1>
                <IoIosArrowForward className="svg-takel" />
              </div>
              <hr className="hr-1-l" />
              <form action="">
                <div className="search-f-h">
                  <input
                              required
                    placeholder="Search"
                    className="typ-ser"
                    type="search"
                    name=""
                    id=""
                  />
                  <button className="search-take-b bt-gg">SEARCH</button>
                </div>
              </form>
              <div className="take-fl-2">
                <h1 className="take-lh2">Blog Categories</h1>
                <IoIosArrowForward className="svg-takel" />
              </div>
              <hr className="hr-1-l" />
              <div className="top-fgi">
                <div>
                  <div className="col-1-t">
                    <span className="spa-5-t"></span>
                    <h1 className="take-th-1">Business (1)</h1>
                  </div>
                  <hr className="hr-io" />
                </div>
                <div>
                  <div className="col-1-t">
                    <span className="spa-5-t"></span>
                    <h1 className="take-th-1">Entertainment (1)</h1>
                  </div>
                  <hr className="hr-io" />
                </div>
                <div>
                  <div className="col-1-t">
                    <span className="spa-5-t"></span>
                    <h1 className="take-th-1">Lifestyle (2)</h1>
                  </div>
                  <hr className="hr-io" />
                </div>
                <div>
                  <div className="col-1-t">
                    <span className="spa-5-t"></span>
                    <h1 className="take-th-1">Nutrition Tips (1)</h1>
                  </div>
                  <hr className="hr-io" />
                </div>
                <div>
                  <div className="col-1-t">
                    <span className="spa-5-t"></span>
                    <h1 className="take-th-1">Tip & Trick (1)</h1>
                  </div>
                  <hr className="hr-io" />
                </div>
                <div>
                  <div className="col-1-t">
                    <span className="spa-5-t"></span>
                    <h1 className="take-th-1">Uncategorized (2)</h1>
                  </div>
                  <hr className="hr-io" />
                </div>
              </div>
              <div className="take-fl-3">
                <h1 className="take-lh3">Latest Post</h1>
                <IoIosArrowForward className="svg-takel" />
              </div>
              <hr className="hr-1-l" />
              <div className="gat-gg">

              <div className="latest-post-t">
                <div>
                  <img className="latest-img-up" src={require('./Assets/blog_8-m.jpeg')} alt="" />
                </div>
                <div className="update-latest">
                  <p className="para-latest1">Denim Trends 2021 Take Kids Fashion To A Whole</p>
                  <p>May 24, 2022</p>
                </div>
              </div>
              <div className="latest-post-t">
                <div>
                  <img className="latest-img-up" src={require('./Assets/blog_7-m.jpeg')} alt="" />
                </div>
                <div className="update-latest">
                  <p className="para-latest1">Denim Trends 2021 Take Kids Fashion To A Whole</p>
                  <p>May 24, 2022</p>
                </div>
              </div>
              <div className="latest-post-t">
                <div>
                  <img className="latest-img-up" src={require('./Assets/blog_6-m.jpeg')} alt="" />
                </div>
                <div className="update-latest">
                  <p className="para-latest1">Denim Trends 2021 Take Kids Fashion To A Whole</p>
                  <p>May 24, 2022</p>
                </div>
              </div>
              <div className="latest-post-t">
                <div>
                  <img className="latest-img-up" src={require('./Assets/blog_5-m.jpeg')} alt="" />
                </div>
                <div className="update-latest">
                  <p className="para-latest1">Denim Trends 2021 Take Kids Fashion To A Whole</p>
                  <p>May 24, 2022</p>
                </div>
              </div>
              <div className="latest-post-t">
                <div>
                  <img className="latest-img-up" src={require('./Assets/blog_4-m.jpeg')} alt="" />
                </div>
                <div className="update-latest">
                  <p className="para-latest1">Denim Trends 2021 Take Kids Fashion To A Whole</p>
                  <p>May 24, 2022</p>
                </div>
              </div>
              </div>
              <div className="take-fl-4">
                <h1 className="take-lh4">Tags
</h1>
                <IoIosArrowForward className="svg-takel" />
              </div>
              <hr className="hr-1-l" />

              <div className="btn-ttr">
                <button className="btn-side-bar-take">Energy</button>
                <button className="btn-side-bar-take">Fitness</button>
                <button className="btn-side-bar-take">Healthy</button>
                <button className="btn-side-bar-take">Nutrition</button>
                <button className="btn-side-bar-take">Powders</button>
                <button className="btn-side-bar-take">Protein</button>
                <button className="btn-side-bar-take">Snacks</button>
                <button className="btn-side-bar-take">Wellness</button>

              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Blog;
