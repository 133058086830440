import React from 'react'
import './VlogPlaylistCard.css'
import Image1 from '../../Vlog Card/Assets/razer-wallpapers-page-hero-mobile.jpg'

const VlogPlaylistCard = () => {
  return (
<div className="VlogPlaylistCard">
<div className="VlogPlaylistCardLeft">
    <img src={Image1} alt="" />
</div>
<div className="VlogPlaylistCardRight">
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sint deserunt vitae.
        
    </p>
</div>
</div>
  )
}

export default VlogPlaylistCard