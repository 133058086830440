import React, { useEffect, useState } from "react";
import ProductCarouselWithCategory from "../Mini-Components/LatestProductCarousel/ProductCarouselWithCategory";
import ProductCarouselWithCategoryRight from "../Mini-Components/LatestProductCarousel/ProductCarouselWithCategotyRight";
import "./Shop.css";
import FourCard from "./ShopCompo/FourCard";
import TwoCard from "./ShopCompo/TwoCard/TwoCard";
import WhatisGoal from "./ShopCompo/WhatisGoal/WhatisGoal";
import { onSnapshot, query, where, collection } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import {
  Bcaa,
  Creatine,
  Dailyessentials,
  FatBurner,
  Preworkout,
  wheyProtein,
} from "./Category";
import { BeatLoader } from "react-spinners";

const Shop = () => {
  const [discountProduct, setDiscountProduct] = useState();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "Protein")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setProduct(cities);
      setIsLoading(false);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);
  const [gainer, setGainer] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "Gainer")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setGainer(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const [isolate, setIsolate] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "Protein-Isolate")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setIsolate(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const combine = (product, gainer, isolate) => {
    return [...product, ...gainer, ...isolate];
  };
  const protein = combine(product, gainer, isolate);

  const [preworkout, setPreworkout] = useState();

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "Pre-Workout")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setPreworkout(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const [creatine, setCreatine] = useState();

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "Creatine")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setCreatine(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const [bcaa, setBcaa] = useState();

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "BCAA-&-EAA")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setBcaa(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const [fishoil, setFishoil] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "FishOil")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setFishoil(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const [multivitamin, setMultivitamin] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "products"),
      where("category", "==", "Multi-Vitamin")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push(doc.data());
      });
      setMultivitamin(cities);
      setDiscountProduct(cities.slice(Math.max(cities.length - 4, 2)));
    });

    return () => unsubscribe();
  }, []);

  const combine2 = (multivitamin, fishoil) => {
    return [...multivitamin, ...fishoil];
  };
  const daily = combine2(multivitamin, fishoil);

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"white"} loading={isLoading} />
        </div>
      </>
    );
  }

  return (
    <div className="Shop">
      <div className="ShopHead">
        <h1 className="ShopHeadH1">BUNDLE AND SAVE</h1>
        <h3 className="ShopHeadH3">
          {" "}
          <span className="ShopHeadSpan">20% OFF</span>ANY 3 PRODUCTS
        </h3>
      </div>
      <div className="ShopProductByCategory">
        <WhatisGoal />
        <ProductCarouselWithCategory
          category={wheyProtein}
          head={"PROTEIN"}
          product={protein}
        />
        {/* <ProductCarouselWithCategoryRight/> */}
        <ProductCarouselWithCategoryRight
          category={Preworkout}
          head={"PRE WORKOUT"}
          product={preworkout}
        />
        <ProductCarouselWithCategory
          category={Creatine}
          head={"CREATINE"}
          product={creatine}
        />
        <ProductCarouselWithCategoryRight
          category={Bcaa}
          head={"BCAA/EAA"}
          product={bcaa}
        />
        <ProductCarouselWithCategory
          category={Dailyessentials}
          head={"DAILY ESSENTIALS"}
          product={daily}
        />
        <FourCard />
      </div>
    </div>
  );
};

export default Shop;
