import React from "react";
import './WhatisGoal.css'
import IMG1 from '../../../../Assets/WhatisGoal/h2_img5.jpg'
import IMG2 from '../../../../Assets/WhatisGoal/h2_img6.jpg'
import IMG3 from '../../../../Assets/WhatisGoal/h2_img7.jpg'
import IMG4 from '../../../../Assets/WhatisGoal/h2_img8.jpg'


import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const WhatisGoal = () => {



  return (
    <div>
      <div className="heading-c font4">
        <h1>What is your Goal</h1>
        <IoIosArrowForward />
      </div>
      <div className="head-1">
        <div className="font4 Head-goal">
            {/* <Link to={'/ProductList'}> */}
          <div className="head-gg">
            <div className="Goal-img-texts">
              <h1 className=" Goal-h1">Build Muscle </h1>
              <h1 className=" Goal-h2">View Products</h1>
            </div>
            <div className="img-goal">
              <img className="img-goal-img1" src={IMG1} alt="" />
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to={'/ProductList'}> */}

          <div className="head-gg">
            <div className="Goal-img-texts">
              <h1 className=" Goal-h1">LOSE WEIGHT</h1>
              <h1 className=" Goal-h2">View Products</h1>
            </div>
            <div className="img-goal">
              <img className="img-goal-img2" src={IMG2} alt="" />
            </div>
          </div>
          {/* </Link> */}

          {/* <Link to={'/ProductList'}> */}
            
          <div className="head-gg">
            <div className="Goal-img-texts11">
              <h1 className=" Goal-h1">IMPROVE ENDURANCE</h1>
              <h1 className=" Goal-h2">View Products</h1>
            </div>
            <div className="img-goal">
              <img className="img-goal-img3" src={IMG3} alt="" />
            </div>
          </div>
          {/* </Link> */}

          {/* <Link to={'/ProductList'}> */}
            
          <div className="head-gg">
            <div className="Goal-img-texts">
              <h1 className=" Goal-h1">STAY HEALTHY</h1>
              <h1 className=" Goal-h2">View Products</h1>
            </div>
            <div className="img-goal">
              <img className="img-goal-img4" src={IMG4} alt="" />
            </div>
          </div>
          {/* </Link> */}

        </div>
      </div>
      {/* Mobile */}
      <div className="head-11">
        <div className="font4 Head-goal1">
          <div className="head-gg1">
            <div className="Goal-img-texts1">
              <h1 className=" Goal-h11">Build Muscle </h1>
              <h1 className=" Goal-h21">View Products</h1>
            </div>
            <div className="img-goal1">
              <img  className="img-goal-img11" src={IMG1} alt="" />
            </div>
          </div>
          <div className="head-gg1">
            <div className="Goal-img-texts1">
              <h1 className=" Goal-h11">LOSE WEIGHT</h1>
              <h1 className=" Goal-h21">View Products</h1>
            </div>
            <div className="img-goal1">
              <img src={IMG2} alt="" />
            </div>
          </div>
          <div className="head-gg1">
            <div className="Goal-img-texts11">
              <h1 className=" Goal-h11">IMPROVE ENDURANCE</h1>
              <h1 className=" Goal-h21">View Products</h1>
            </div>
            <div className="img-goal1">
              <img src={IMG3} alt="" />
            </div>
          </div>
             <div className="head-gg1">
            <div className="Goal-img-texts1">
              <h1 className=" Goal-h11">STAY HEALTHY</h1>
              <h1 className=" Goal-h21">View Products</h1>
            </div>
            <div className="img-goal1">
              <img  className="img-goal-img12" src={IMG4} alt="" />
            </div>
          </div>
        </div>
      </div>
    
{/* <div   class={state ? "colo-g" : " colo-f "} >


<h1>hhhh</h1>
</div> */}

    </div>
  );
};

export default WhatisGoal;
