import CategoryPng from "../../Assets/CardShopByCategory/product_2.png";
import CategoryPng1 from "../../Assets/CardShopByCategory/product_24.png";
import CategoryPng2 from "../../Assets/CardShopByCategory/product_26.png";
import CategoryPng3 from "../../Assets/CardShopByCategory/Muscletech-Hydroxycut.png";
import CategoryPng4 from "../../Assets/CardShopByCategory/universal_nutrition_animal_packs.png";
import CategoryPng5 from "../../Assets/CardShopByCategory/Product-45.png";
import CardPng from "../../Assets/CardShopBy/h1_img1.png";
import CardPng1 from "../../Assets/CardShopBy/h1_img2.png";
import CardPng2 from "../../Assets/CardShopBy/h1_img3.png";
export const wheyProtein = [
  { id: 1, category: "WHEY PROTEIN", collection: "Protein" },
  { id: 2, category: "WHEY PROTEIN ISOLATE", collection: "Protein-Isolate" },
  { id: 3, category: "WEIGHT GAINERS", collection: "Gainer" },
  { id: 3, category: "View All", collection: "Protein,Protein-Isolate,Gainer" },
  // { id: 4, category: "CASEIN PROTEIN" },
  // { id: 5, category: "EGG PROTEIN" },
  // { id: 6, category: "PLANT PROTEIN" },
];

export const Preworkout = [
  // { id: 1, category: "Pre workout" },
  // { id: 2, category: "Non stimulating " },
  // { id: 3, category: "Stimulating " },
];
export const FatBurner = [
  // { id: 1, category: "Fat burner " },
  // { id: 2, category: "Non stimulating " },
  // { id: 3, category: "Stimulating" },
];
export const Bcaa = [
  { id: 1, category: "Bcaa ", collection: "BCAA-&-EAA" },
  { id: 2, category: "Eaa", collection: "BCAA-&-EAA" },
  // { id: 3, category: "Instant carbs" },
];
export const Dailyessentials = [
  { id: 1, category: "Multi Vitamins", collection: "Multi-Vitamin" },
  { id: 2, category: "Fish Oil", collection: "FishOil" },
  { id: 3, category: "View All", collection: "Multi-Vitamin,FishOil" },
];
export const Creatine = [
  // { id: 1, category: "Creatine", collection: "Creatine" },
  // { id: 2, category: "Non stimulating " },
  // { id: 3, category: "Stimulating" },
];

export const Popular = [
  {
    id: 1,
    img: CategoryPng,
    category: "Protein",
    collection: "Protein,Protein-Isolate,Gainer",
  },
  {
    id: 2,
    img: CategoryPng1,
    category: "Bcaa & Eaas",
    collection: "BCAA-&-EAA",
  },
  {
    id: 3,
    img: CategoryPng2,

    category: "Pre Workout",
    collection: "Pre-Workout",
  },
  {
    id: 4,
    img: CategoryPng3,

    category: "Fat Burners",
    collection: "Multi-Vitamin,FishOil",
  },
  {
    id: 5,
    img: CategoryPng4,

    category: "Vitamin & Health",
    collection: "Multi-Vitamin,FishOil",
  },
  {
    id: 6,
    img: CategoryPng5,

    category: "Creatine",
    collection: "Creatine",
  },
];

export const Goal = [
  {
    id: 1,
    img: CardPng,
    category: "BUILD YOUR BURN",
    description:'Get Cut',
    collection: "Pre-Workout,Creatine",
  },
  {
    id: 2,
    img: CardPng1,
    category: "BUILD YOUR PERFORMANCE",
    description:'Get Powerful',

    collection: "Protein,Protein-Isolate,Gainer",
  },
  {
    id: 3,
    img: CardPng2,

    category: "BUILD YOUR STRENGTH",
    description:'Get Muscles',

    collection: "BCAA-&-EAA,Multi-Vitamin,FishOil",
  },
];
