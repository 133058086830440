/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import Logo from "../Assets/Logo.png";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../Firebase/Firebase";
import { useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import Profile from "../Assets/profile.jpeg";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";



import { FiChevronRight } from "react-icons/fi";
import { basketTotal } from "./Redux/basketSlice";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import Nav2 from "./Nav2";
import { Popular } from "./Shop/Category";
import Test from "./Test";
import Search from "./Search/Search";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  border-radius: 15px;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;



const Navbar = () => {


  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return []; // Set a default value when there's no data in local storage
    }
  };  

  


  const [isOpen, setIsOpen] = useState(false);
  const [wishlist, setWishlist] = useState(getDatafromLS());

  // saving data to local storage

  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "user", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No Document Found");
          }
        });
      } else {
        console.log("no user");

        setUser();
      }
    });
  }, []);
  const navigate = useNavigate();

  const tabnav = () => {
    setIsOpen(false);
    navigate("/Profile");
  };

  const { cart, totalQuantity } = useSelector((state) => state.allCart);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(basketTotal());
  }, [cart]);



  return (
    <>
      <div className="Navbar">
        <div>
          <nav className="NavbarMenu">
            <NavLink to={"/"}>
              <img className="NavBarMenuLogo" src={Logo} alt="" />
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? "NavBarMenuNavlink-active" : "NavBarMenuNavlink"
              }
              to={"/"}
            >
              <div className="NavBarMenuNavLinkDiv">
                <p>HOME</p> <span className="NavBarMenuNavLinkSpan"></span>
              </div>
            </NavLink>

            <div class="dropdown">
              <button class="dropbtn">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "NavBarMenuNavlink-active" : "NavBarMenuNavlink"
                  }
                  to={"/Shop"}
                >
                  <div className="NavBarMenuNavLinkDiv">
                    <p>SHOP</p> <span className="NavBarMenuNavLinkSpan"></span>
                  </div>
                </NavLink>
              </button>
              <div class="dropdown-content">
                {Popular.map((item) => (
                  <div
                    onClick={() =>
                      navigate("/ProductList", { state: { item } })
                    }
                    className=""
                  >
                    {/* <img className="CardCategoryImage" src={item.img} alt="" /> */}
                    <span className="nav-cat-spa">{item.category}</span>
                  </div>
                ))}
              </div>
            </div>
            <Search />

            {/* <NavLink
            className={({ isActive }) =>
              isActive ? "NavBarMenuNavlink-active dropdown" : "NavBarMenuNavlink dropdown"
            }
            to={"/Blog"}
          >
            <div className="NavBarMenuNavLinkDiv">
              <p>ARTICLES</p> <span className="NavBarMenuNavLinkSpan"></span>
            </div>
            <div class="dropdown-content">
              <Link to={'/Blog'}>
      <span>Blog</span>
              </Link>
              <Link to={'/Vlog'}>
      <span>Vlog</span>
              </Link>
    </div>
          </NavLink> */}
            {/* <NavLink
            className={({ isActive }) =>
              isActive ? "NavBarMenuNavlink-active" : "NavBarMenuNavlink"
            }
            to={"/Workouts"}
          >
            <div className="NavBarMenuNavLinkDiv">
              <p>WORKOUTS</p> <span className="NavBarMenuNavLinkSpan"></span>
            </div>
          </NavLink> */}
            {/* <NavLink
            className={({ isActive }) =>
              isActive ? "NavBarMenuNavlink-active" : "NavBarMenuNavlink"
            }
            to={"/Forum"}
          >
            <div className="NavBarMenuNavLinkDiv">
              <p>FORUM</p>
              <span className="NavBarMenuNavLinkSpan"></span>
            </div>
          </NavLink> */}
          </nav>
        </div>
        <div className="NavbarMenu2">
          {/* <form className="NavbarMenu2Component1">
          <input
            className="NavbarMenu2Input"
            placeholder="Search Products..."
            type="text"
          />
          <button className="NavbarMenu2Button">
            <AiOutlineSearch size={19} />
          </button>
        </form> */}
          <div className="NavbarMenu2Component2">
            <div className="nav-search-section">
              {user ? (
                <>
                  <div
                    className="nav-use-r"
                    onClick={() => navigate("Profile")}
                  >
                    <img
                      style={{ borderRadius: "50%" }}
                      src="https://secure.gravatar.com/avatar/daa159084eea76b3d9ef1c5a54b89dc1?s=24&d=mm&r=g"
                      alt=""
                    />
                    <span
                      style={{ cursor: "pointer", textTransform: "capitalize" }}
                      className="NavbarMenu2Component2Span3 d-nn-nav1"
                    >
                      {user.name}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <NavLink
                    to={"/Login"}
                    className="NavbarMenu2Component2NavLink link-sign-up"
                  >
                    <AiOutlineUser
                      size={25}
                      className="NavbarMenu2Component2Icon"
                    />
                    <span className="NavbarMenu2Component2Span">
                      Sign In/Register
                    </span>
                  </NavLink>
                </>
              )}
            </div>
            {/* )} */}
          </div>
          <div className="NavbarMenu2Component3">
            <NavLink to={"/Search"} className="NavbarMenu2Component2NavLink">
              <BiSearch size={25} className="nav-search-icon" />
            </NavLink>

            <NavLink to={"/Wishlist"} className="NavbarMenu2Component2NavLink">
              <AiOutlineHeart size={25} className="NavbarMenu2Component2Icon" />
              {/* <span className="NavbarMenu2Component3Count">
                {wishlist.length}
              </span> */}
            </NavLink>
            <NavLink to={"/Cart"} className="NavbarMenu2Component2NavLink">
              <AiOutlineShoppingCart
                size={25}
                className="NavbarMenu2Component2Icon"
              />
              <span className="NavbarMenu2Component3Count">
                {totalQuantity}
              </span>
            </NavLink>
          </div>
          <div className="nav-op-btn">
            <HiOutlineMenuAlt3
              size={25}
              style={{ cursor: "pointer" }}
              color="black"
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
      </div>

      <div>
        <StyledOffCanvas isOpen={isOpen} className={isOpen ? "r-10-nav" : ""}>
          <div className={isOpen ? "head-mobile-menu" : "none-d-sidenav"}>
            <div className="close-icon-nav">
              <GrClose
                color="black"
                size={20}
                onClick={() => setIsOpen(false)}
              />
            </div>

            <div className="nav-mb-link">
              {user ? (
                <>
                  <div className="nav-uss-dr" onClick={tabnav}>
                    <div className="nav-use-r2 ">
                      <img
                        style={{ borderRadius: "50%" }}
                        src="https://secure.gravatar.com/avatar/daa159084eea76b3d9ef1c5a54b89dc1?s=24&d=mm&r=g"
                        alt=""
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        className="NavbarMenu2Component2Span3"
                      >
                        {user.name}
                      </span>
                    </div>
                    <FiChevronRight size={20} color="black" />
                  </div>
                </>
              ) : (
                <>
                  <Link to={"Login"}>
                    <div
                      className="side-nvv-c"
                      onClick={() => setIsOpen(false)}
                    >
                      <span style={{ fontSize: " 18px", color: "black" }}>
                        Sign in / Register
                      </span>
                      <span>
                        <FiChevronRight size={20} />
                      </span>
                    </div>
                  </Link>
                </>
              )}

              <Link to={"/"}>
                <div className="side-nvv-c" onClick={() => setIsOpen(false)}>
                  <span style={{ fontSize: " 18px", color: "black" }}>
                    Home
                  </span>
                  <span>
                    <FiChevronRight size={20} />
                  </span>
                </div>
              </Link>
              <Link to={"Shop"}>
                <div className="side-nvv-c" onClick={() => setIsOpen(false)}>
                  <span style={{ fontSize: " 18px", color: "black" }}>
                    Shop
                  </span>
                  <span>
                    <FiChevronRight size={20} />
                  </span>
                </div>
              </Link>

              {Popular.map((item) => (
                <div
                  onClick={() => navigate("/ProductList", { state: { item } })}
                  className=""
                >
                  {/* <img className="CardCategoryImage" src={item.img} alt="" /> */}
                  <span className="nav-cat-spa"></span>

                  <div className="side-nvv-c" onClick={() => setIsOpen(false)}>
                    <span style={{ fontSize: " 18px", color: "black" }}>
                      {item.category}
                    </span>
                    <span>
                      <FiChevronRight size={20} color="black" />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </StyledOffCanvas>
        <StyledOffCanvasOverlay
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
        />
      </div>
    </>
  );
};

export default Navbar;
