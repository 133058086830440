import React from 'react'
import './ForumCard1.css'
import BG1 from '../../../Assets/blog_8-820x500.jpeg'
import { Link } from 'react-router-dom'

const ForumCard1 = () => {
  return (
   <div className="ForumCard1">
    <img className="ForumCard1IMG" src={BG1} alt="" />
    <div className="ForumCard1Top">
      <p className="ForumCard1TopP">BUSINESS</p>
      <span className="ForumCard1TopSpan"></span>
      <p  className="ForumCard1TopP2">NOV 24, 2022</p>
    </div>
    <p className="ForumCard1P">Denim Trends 2021 Take Kids Fashion To A Whole New Level </p>
    <p className="ForumCard1P2"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam corrupti iste dolorem ut, aliquam facilis ex voluptas ad sint officiis.</p>
<Link to={'/BlogName'}>
<button className="ForumCard1Button">READ MORE</button>
</Link>
   </div>
  )
}

export default ForumCard1