import { collection, onSnapshot } from "firebase/firestore";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { db } from "../../Firebase/Firebase";
import { Link, useNavigate } from "react-router-dom";
import "../Navbar.css";
import "./Search.css";
import { BiSearch } from "react-icons/bi";
import { MdKeyboardArrowLeft } from "react-icons/md";



import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Popular } from "../Shop/Category";

const Search1 = () => {
  const navigate = useNavigate();

  const [category, setcategory] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Protein");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [category2, setcategory2] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "BCAA-&-EAA");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory2(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [category3, setcategory3] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Fish");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory3(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [category4, setcategory4] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Gainer");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory4(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [category5, setcategory5] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Isolate");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory5(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [category6, setcategory6] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Multi-Vitamin");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory6(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [category7, setcategory7] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Pre-Workout");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory7(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [category8, setcategory8] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Protein-Isolate");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory8(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [category9, setcategory9] = useState([]);

  useEffect(() => {
    const q = collection(db, "category", "product", "Creatine");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcategory9(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [result, setResult] = useState([]);

  useEffect(() => {
    // Combine the arrays whenever any of them changes
    const combinedArray = [
      ...category,
      ...category2,
      ...category3,
      ...category4,
      ...category5,
      ...category6,
      ...category7,
      ...category8,
      ...category9,
    ];
    setResult(combinedArray);
  }, [
    category,
    category2,
    category3,
    category4,
    category5,
    category6,
    category7,
    category8,
    category9,
  ]);



  const [search, setSearch] = useState();

  const countries = useMemo(() => {
    try {
      if (!search) return result;

      return result.filter((item) => {
        return item.id.toLowerCase().includes(search.toLowerCase());
      });
    } catch (err) {
      console.log(err);
    }
  }, [search, result]);
  const [searchtab, setSearchtab] = useState(false);
  const divRef = useRef(null);

  const searchtabs = useCallback(() => {
    setSearchtab(search?.length > 1);
  }, [search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setSearchtab(false);
        setSearch("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    searchtabs(); // Call searchtabs whenever the input changes.
  };

  const clicknavi = (item) => {
    navigate("/ProductPrev", { state: { from: item?.name } });
    setSearchtab(false);
    setSearch("");
  };
  return (
    <div className="search1-d1">
      <div className="search1-d2">
        <div className="search-d3">
          <MdKeyboardArrowLeft color="black" size={35}   onClick={() => navigate("/")} />
          <input
            type="search"
            onChange={handleInputChange}
            value={search}
            placeholder="Search products…"
            name=""
            id=""
          />
        </div>
        <div>
          <div className="search-result-section">
            {searchtab && (
              <div>
                <div className="search1-d6">
                  {countries.length === 0 ? (
                    <>
                      <h1 className="no-res-af-ser">No results</h1>
                    </>
                  ) : (
                    <>
                    <p className="head-cat-ser">Brands </p>
                      {Array.isArray(result)
                        ? countries.map((item, key) => {
                            return (
                              <>
                                <div className="search1-d7" onClick={() => clicknavi(item)}>
                                  <div className="search1-d8">
                                    <p >
                                      {item.name}
                                    </p>
                                  </div>
                                  <div className="svg-search1">

                                  <BiSearch size={20} color="black" />
                                </div>
                                  </div>
                              </>
                            );
                          })
                        : null}
                    </>
                  )}




                  
                </div>
              </div>
            )}




          </div>
          <div className="search1-d6">
          <p className="head-cat-ser">Categories</p>

          {Popular.map((item) => (
            <div className="search1-d7"   onClick={() =>
              navigate("/ProductList", { state: { item } })
            }>
                          <div className="search1-d8">
                            <p >
                            {item.category}
                            </p>
                          </div>
                          <div className="svg-search1">

                          <IoIosArrowForward size={20} color="black" />
                        </div>
                          </div>
                ))}

</div>


        </div>
      </div>
    </div>
  );
};

export default Search1;
