import React from 'react'
import { ImQuotesLeft } from "react-icons/im";
import { IoArrowUndoOutline } from "react-icons/io5";
import { IoIosArrowBack } from 'react-icons/io';
import { AiFillHome } from 'react-icons/ai';

const VlogName = () => {
  return (
   <div className="BlogName">
  <div className="take-r1">
            <div className="take-r">
              <div className="take-h-4">
                <h1 className="take-h4"><AiFillHome/>Home</h1>
                <span className="take-sppa"></span>
                <h1 className="take-h4">Vlog</h1>
                <span className="take-sppa"></span>
                <h1 className="take-h4">Vlog-Name</h1>
              </div>
              <div className="take-h-5">
                <h1 className="take-h6">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo, hic.
                </h1>
              </div>
              <div className="take-ii">
                <img
                  className="take-img-m"
                  src={require("../Vlog Card/Assets/razer-wallpapers-page-hero-mobile.jpg")}
                  alt=""
                />
              </div>
              <div className="para-take">
                <p className="take-para-1">
                  <span className="span-take-2">L</span>orem ipsum dolor sit
                  amet, consectetur adipisicing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </p>
                <p className="take-para-2">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </p>
                <p className="take-para-3">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum. Sed ut
                  perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p>
              </div>
              <div>
                <div className="take-h-6">
                  <div className="take-h-7">
                    <span className="span-take-3">
                      <ImQuotesLeft />
                    </span>
                    <h1 className="take-h7">
                      A musician must make music, an artist must paint, a poet
                      must write, if he is to be ultimately at peace with
                      himself. What a man can be, he must be
                    </h1>
                  </div>
                  <h1 className="take-h8">- ABRAHAM MASLOW</h1>
                </div>
                <div className="para-take2">
                  <p className="take-para-4">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p className="take-para-5">
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                  <p className="take-para-6">
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum. Sed
                    ut perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam, eaque
                    ipsa quae ab illo inventore veritatis et quasi architecto
                    beatae vitae dicta sunt explicabo.
                  </p>
                </div>
                <div className="take-h-9">
                  <img
                    className="take-im-g"
                    src={require("./Assets/single-blog-2.jpeg")}
                    alt=""
                  />
                  <img
                    className="take-im-g1"
                    src={require("./Assets/single-blog-1.jpeg")}
                    alt=""
                  />
                </div>
                <div className="take-ul-h">
                  <ul className="take-ul-1">
                    <li>
                      So we came up with the idea that instead of limiting the
                      lab to a restricted area of ​​the building
                    </li>
                    <li>
                      We make it quick, easy and convenient for scheduling to
                      save time
                    </li>
                    <li>
                      Our promise is to be respectful of you and your home as if
                      it were our own
                    </li>
                    <li>
                      We stand behind our work with a one-year guarantee on all
                      labor
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="para-8">
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </p>
                </div>
                <div className="take-b-hh">
                  <button className="take-btn-h">Energy</button>
                  <button className="take-btn-h">Healthy</button>
                  <button className="take-btn-h">Powders</button>
                  <button className="take-btn-h">Protein</button>
                  <button className="take-btn-h">Wellness</button>
                </div>
                <div className="border-take-unknown">
                  <div className="abou-bb">
                    <div className="unknown-p">
                      <img
                        className="unknown-p-img"
                        src={require("./Assets/unknown1.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <h1 className="take-h11">About admin</h1>
                      <p className="para-p7">
                        Dolor morbi non arcu risus quis varius. Sit amet est
                        placerat in egestas erat imperdiet. Et magnis dis
                        parturient montes nascetur. Egestas pretium aenean
                        pharetra magna ac placerat vestibulum.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="abou-bb1">
                  <div className="unknown-p11">
                    <img
                      className="unknown-p-img11"
                      src={require("./Assets/blog_7-m.jpeg")}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="span-take7">
                      <span className="spa-uu7">
                        <IoIosArrowBack />
                      </span>
                      <h1 className="take-h12">PREV</h1>
                    </div>
                    <p className="para-p8">
                      How To Teach Children To Dress Themselves: The Ultimate
                      Guide
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="hr-1-take">
                  <hr className="take-hr-1" />
                </div>

                <div>
                  <h1 className="take-h13">3 Comments</h1>
                </div>
                <div className="comm-sec-h">
                  <div className="comment-1-take">
                    <div>
                      <img
                        className="unknown-p-img2"
                        src={require("./Assets/unknown2.png")}
                        alt=""
                      />
                    </div>

                    <div className="arro-take-5"></div>
                    <div className="comment-sec-take">
                      <div>
                        <h1 className="take-h14">Joe Doe</h1>
                        <h1 className="comment-date">June 8, 2022</h1>
                        <p className="para-9">
                          This is exactly what i was looking for, thank you so
                          much for these tutorials
                        </p>
                      </div>
                      <div>
                        <button className="btn-take-reply">
                          <span className="span-7-take">
                            <IoArrowUndoOutline />
                            Reply
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="comment-2-take">
                    <div>
                      <img
                        className="unknown-p-img3"
                        src={require("./Assets/unknown2.png")}
                        alt=""
                      />
                    </div>

                    <div className="arro-take-6"></div>
                    <div className="comment-sec-take2">
                      <div>
                        <h1 className="take-h15">Joe Doe</h1>
                        <h1 className="comment-date2">June 8, 2022</h1>
                        <p className="para-10">
                          This is exactly what i was looking for, thank you so
                          much for these tutorials
                        </p>
                      </div>
                      <div>
                        <button className="btn-take-reply1">
                          <span className="span-8-take">
                            <IoArrowUndoOutline />
                            Reply
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="comment-1-take">
                    <div>
                      <img
                        className="unknown-p-img2"
                        src={require("./Assets/unknown2.png")}
                        alt=""
                      />
                    </div>

                    <div className="arro-take-5"></div>
                    <div className="comment-sec-take">
                      <div>
                        <h1 className="take-h14">Joe Doe</h1>
                        <h1 className="comment-date">June 8, 2022</h1>
                        <p className="para-9">
                          This is exactly what i was looking for, thank you so
                          much for these tutorials
                        </p>
                      </div>
                      <div>
                        <button className="btn-take-reply">
                          <span className="span-7-take">
                            <IoArrowUndoOutline />
                            Reply
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h1 className="take-h16">Leave a Reply</h1>
                    <p className="para-11">
                      Your email address will not be published. Required fields
                      are marked <span className="star-sta">*</span>
                    </p>
                  </div>
                  <div className="take-form">
                    <form>
                      <div className="for-o-take">
                        <div>
                          <input
                                 required
                            className="inputs-take"
                            type="name"
                            name="name"
                            id=""
                            placeholder="Your Name*"
                          />
                        </div>
                        <div>
                          <input
                               required
                            className="inputs-take"
                            type="email"
                            name="email"
                            id=""
                            placeholder="Email Address*"
                          />
                        </div>
                        <div>
                          <input
                                   required
                            className="inputs-take"
                            type="website"
                            name="website"
                            id=""
                            placeholder="Your Website"
                          />
                        </div>
                      </div>
                      <div>
                        <textarea
                   required
                          placeholder="Comment"
                          className="text-aa-take"
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                      <div className="check-take">
                        <input type="checkbox" name="" id=""            required />
                        <p className="para-12">
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </p>
                      </div>
                      <div className="post-ty8">
                        <button className="post-btn-take">
                          <span>POST COMMENT</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
   </div>
  )
}

export default VlogName