import React from "react";
import "./FourCard.css";
import "./FourCard.scss";
import IMG1 from "../../../Assets/FourCard/h2_img1.jpg";
import IMG2 from "../../../Assets/FourCard/h2_img3.jpg";
import IMG3 from "../../../Assets/FourCard/h2_img2.jpg";
import IMG4 from "../../../Assets/FourCard/h2_img4.jpg";

const FourCard = () => {
  return (
    <div>
      <div className="head-f-card-s">
        <div className="four-ds lh-fc">
          <div className="fo1">
            <img className="foimg" src={IMG1} alt="" />

            <div className="foc">
              <h1 className="foc-h1">BUILD MUSCLE</h1>
              <h1 className="foc-h2">
                PROTEIN POWDER <br />
                COLLECTION
              </h1>
              <p className="foc-p1">up to 15% off</p>
              <button class="btn-hh shop-b">
                <span>Shop now</span>
              </button>
            </div>
          </div>
          <div className="fo1">
            <img className="foimg" src={IMG2} alt="" />

            <div className="foc2">
              <h1 className="foc-h1">SUPER DEAL</h1>
              <h1 className="foc-h2">
              BUY 1 GET 1 FREE <br />
              GOLD NUTRITION
              </h1>
  
              <button class="btn-hh shop-b">
                <span>Shop now</span>
              </button>
            </div>
          </div>
        </div>

        <div className="four-ds rh-fc">
          <div className="fo1">
            <img className="foimg" src={IMG3} alt="" />

            <div className="foc3">
              <h1 className="foc-h1" style={{color:'white'}}>IMMUNEBOOSTERS</h1>
              <h1 className="foc-h2-1">
             UP TO <br />
             <span className="sppa">

            60 % OFF
             </span>
              </h1>
           
              <button class="btn-hh shop-b">
                <span>Shop now</span>
              </button>
            </div>
          </div>
          <div className="fo1">
            <img className="foimg" src={IMG4} alt="" />

            <div className="foc4">
              <h1 className="foc-h1" style={{color:'white'}}>SAVE BIG ON YOUR FAVS</h1>
              <h1 className="foc-h2">
              SAVE BIG ON YOUR FAVS
              </h1>
         
              <button class="btn-hh shop-b">
                <span>Shop now</span>
              </button>
            </div>
          </div>
        </div>
      </div>
{/* 
      <div className="main-gg">
        <div className="main-gh">
          <div className="headG-1">
            <div className="card-lefth-h">
              <div className="main-g11 pad-1 ">
                <div className="text-hh center-t">
                  <h1 className="build-t">BUILD MUSCLE</h1>
                  <h1 className="build-h2">
                    PROTEIN POWDER <br />
                    COLLECTION
                  </h1>
                  <h1 className="build-h3">up to 15% off</h1>
                  <button class="btn-hh shop-b">
                    <span>Shop now</span>
                  </button>
                </div>
                <div className="img-car1">
                  <img className="img-car2" src={IMG1} alt="" />
                </div>
              </div>
              <div className="main-g11 pad-2 top-p">
                <div className="text-hh1">
                  <h1 className="build-t">SUPER DEAL</h1>

                  <h1 className="build-h2">
                    BUY 1 GET 1 FREE
                    <br /> GOLD NUTRITION
                  </h1>
                  <button class="btn-hh shop-b">
                    <span>Shop now</span>
                  </button>
                </div>
                <div className="img-car1">
                  <img className="img-car2" src={IMG2} alt="" />
                </div>
              </div>
            </div>
            <div className="card-right-h">
              <div className="main-g11  pad-3">
                <div className="text-hh3">
                  <h1 className="build-t" style={{ color: "white" }}>
                    IMMUNEBOOSTERS
                  </h1>

                  <h1 className="build-t2">
                    UP TO
                    <br />
                    <span className="six-t">60% OFF</span>
                  </h1>

                  <button class="btn-hh shop-b">
                    <span>Shop now</span>
                  </button>
                </div>
                <div className="img-car1">
                  <img className="img-car2" src={IMG3} alt="" />
                </div>
              </div>
              <div className="main-g11  pad-4 top-p">
                <div className="text-hh4 center-t ">
                  <h1 style={{ color: "white" }} className="build-t">
                    SAVE BIG ON YOUR FAVS
                  </h1>
                  <h1 className="build-h4">SAVE BIG ON YOUR FAVS</h1>

                  <button class="btn-hh shop-b">
                    <span>Shop now</span>
                  </button>
                </div>
                <div className="img-car1">
                  <img className="img-car2" src={IMG4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* 
      Mobile-gallery
      */}
      {/* <div className="mobile-g">

<div className="gallery1"> 
  <div>
<div className="figcapt ">
          <h1>BUILD MUSCLE</h1>
          <h1>
            PROTEIN POWDER <br /> COLLECTION
          </h1>
          <h1 className="nth3" >up to 15% off</h1>
          <button class="btn-hh shop-b">
              <span>Shop now</span>
            </button>
        </div>
<div className="card-image">
        <img src={IMG1} alt="" />
      </div>
    </div>
 
    <div>
<div className="figcapt capt1">
          <h1>SUPER DEAL</h1>
          <h1>
           BUY 1 GET 1 FREE<br /> GOLD NUTRITION
          </h1>
    
          <button class="btn-hh shop-b">
              <span>Shop now</span>
            </button>
        </div>
<div className="card-image">
        <img src={IMG2} alt="" />
      </div>
    </div>
    <div>
<div className="figcapt">
<h1 style={{color:'white'}}>IMMUNEBOOSTERS</h1>
          <h1>
            UP TO
          </h1>
          <h1 className='figcapt60'>60% OFF</h1>

          <button class="btn-hh shop-b">
              <span>Shop now</span>
            </button>
        </div>
<div className="card-image">
        <img src={IMG3} alt="" />
      </div>
    </div>
    <div className='last-c'>
<div className="figcapt22 capt2">
          <h1 style={{color:'white'}}>SAVE BIG ON YOUR FAVS</h1>
          <h1>
          SUMMER READY SALE
          </h1>
          <button class="btn-hh shop-b">
              <span>Shop now</span>
            </button>
        </div>
<div className="card-image">
        <img src={IMG4} alt="" />
      </div>
    </div>
</div>
</div> */}
    </div>
  );
};

export default FourCard;
