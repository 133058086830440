
import { motion } from "framer-motion";
import { useState } from "react";
import { DynamicStar } from "react-dynamic-star";
import { AiOutlineHeart } from "react-icons/ai";

import Backdrop from "../Backdrop/index";
import './Modal.css'


const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};


const Modal = ({ handleClose , tag , scrollTop , item}) => {



  // eslint-disable-next-line no-unused-vars
  const [star, setStar] = useState({
    rating: 3.5,
    totalStars: 5,
    sharpness: 2.0,
    width: 20,
    height: 20,
    outlined: true,
    outlinedColor: "",
    fullStarColor: "#F65000",
    emptyStarColor: "transparent"
  });



  return (
    <Backdrop scrollTop={scrollTop} onClick={handleClose}>
 
        <motion.div
          onClick={(e) => e.stopPropagation()}
          className="modal orange-gradient"
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <ModalText item={item}  star={star} tag={tag} />
        </motion.div>
    

    </Backdrop>
  );
};

const ModalText = ({ star  , tag , item}) => (
<div className="ModalContainer">
  <div className="ModalContainerLeft">
<img className="ModalContainerLeftImage" src={item?.Image} alt="" />
  </div>
  <div className="ModalContainerRight">
<span className="ModalContainerRightSpan">{item?.name}</span>
<div className="ModalContainerRightStar">

<DynamicStar
        rating={parseFloat(star.rating)}
        width={parseFloat(star.width)}
        height={parseFloat(star.height)}
        outlined={star.outlinedColor ? star.outlinedColor : star.outlined}
        totalStars={star.totalStars}
        sharpnessStar={star.sharpness}
        fullStarColor={star.fullStarColor}
        emptyStarColor={star.emptyStarColor}
        />(5)
        </div>
<span className="ModalContainerRightSpan2">₨ {item?.Dprice}</span>
<br />
<br />
<span className="ModalContainerRightSpan3">{item?.description}</span>
<br />
<div className="ModalContainerRightButtons">
<div className="ModalContainerRightButtons1Part">
  <span className="ModalContainerRightButtons1PartSpan">-</span><input className="ModalContainerRightButtons1Partinput" type="number" value={'1'} disabled name="" id="" /><span className="ModalContainerRightButtons1PartSpan">+</span>
</div>
<button className="ModalContainerRightButtons1">Add To Cart</button>

</div>
<span className="ModalContainerRightSpan4"><AiOutlineHeart />Add To Wishlist</span>
<div className="ModalContainerRightCategory">
  <span className="ModalContainerRightCategorySpan1">Category:</span><span className="ModalContainerRightCategorySpan2">{item?.category}</span>
</div>
<div style={{marginBottom: '25px'}} className="ModalContainerRightCategory">
  <span className="ModalContainerRightCategorySpan1">Tag:</span><span className="ModalContainerRightCategorySpan2">{tag}</span>
</div>
  </div>

</div>
);

export default Modal;
