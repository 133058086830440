import React from 'react'
import './VlogCard.css'
import Image1 from './Assets/razer-wallpapers-page-hero-mobile.jpg'
import { BsFillPlayFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const VlogCard = () => {
  return (
   
    <div className="CardVlog">

   <div className="VlogCard">
      <img className='VlogCardImg' src={Image1} alt="" />
   </div>
      <div className="VlogCardButtonDiv">
        <Link to={'/VlogName'} style={{textDecoration: 'none'}}>
      <button className='VlogCardButton'><BsFillPlayFill size={40}/></button>
        </Link>
      </div>
      <div className="VlogCardTitleDiv">
        <div className="VlogCradbg">
        <p className="VlogCardTitleDivP">SPORTS</p>
        </div>
        <p className='VlogCardTitleDivH1'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium debitis consectetur error numquam dolor deleniti ex neque provident repudiandae nam.</p>
      </div>
    </div>

  
  )
}

export default VlogCard