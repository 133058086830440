// src/component/Gallery.js
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './ProductPrevCarousel.css'
import IMG1 from '../../../../Assets/Products/product_20.jpg'
import IMG2 from '../../../../Assets/Products/product_16.jpg'
import IMG3 from '../../../../Assets/Products/product_17.jpg'
import IMG4 from '../../../../Assets/Products/product_20.jpg'
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../../Firebase/Firebase";


const ProductPrevCarousel = ({item , image}) => {

    const [product,setProduct] = useState(null)

    
    
    //   useEffect(() => {
    //     const q = query(collection(db, "products" , item?.name , 'Carousel') , orderBy("createdAt" , "asc"));
    //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //       const cities = [];
    //       querySnapshot.forEach((doc) => {
    //           cities.push(doc.data());
    //       });
    //       setProduct(cities)
        
    //     });
      
    //   return () => unsubscribe()
    //   }, [])

  return (
    <div className="main-carousel" >
           
    <Carousel  interval="1000" transitionTime="1000" infiniteLoop>
    
        {product === null ? (
    <div>
    <img src={image} alt="" />

</div>
        ) : (
<>
</>
        )}

    
            {/* {product?.map(( item , id) => (
 <div>
 <img src={item?.Image} alt="" />

 </div>
            ))} */}
      
      
      
    </Carousel>
</div>
  )
}

export default ProductPrevCarousel