// import { createSlice } from "@reduxjs/toolkit";


// const initialState = {
//   cart: [],

//   totalQuantity: 0,
//   totalPrice: 0,
// };

// const cartSlice = createSlice({
//   name: "cart",
//   initialState,
//   reducers: {
//     addToBasket: (state, action) => {
//       let find = state.cart.findIndex((item) => item.id === action.payload.id);
//       if (find >= 0) {
//         state.cart[find].quantity += 1;
//       } else {
//         state.cart.push(action.payload);
//       }
//     },

//     basketTotal: (state) => {
//       let { totalQuantity, totalPrice } = state.cart.reduce(
//         (cartTotal, cartItem) => {
//           console.log("carttotal", cartTotal);
//           console.log("cartitem", cartItem);
//           const { price, quantity } = cartItem;
//           console.log(price, quantity);
//           const itemTotal = price * quantity;
//           cartTotal.totalPrice += itemTotal;
//           cartTotal.totalQuantity += quantity;
//           return cartTotal;
//         },
//         {
//           totalPrice: 0,
//           totalQuantity: 0,
//         }
//       );
//       state.totalPrice = parseInt(totalPrice.toFixed(2));
//       state.totalQuantity = totalQuantity;
//     },

//     removeFromBasket: (state, action) => {
//       state.cart = state.cart.filter((item) => item.id !== action.payload);
//     },


//     removeAllItems: (state) => {
//       state.cart = [];
//       state.totalQuantity = 0;
//       state.totalPrice = 0;
//     },



//     increaseItemQuantity: (state, action) => {
//       state.cart = state.cart.map((item) => {
//         if (item.id === action.payload) {
//           return { ...item, quantity: item.quantity + 1 };
//         }
//         return item;
//       });
//     },
//     decreaseItemQuantity: (state, action) => {
//       state.cart = state.cart.map((item) => {
//         if (item.id === action.payload) {
//           return { ...item, quantity: item.quantity - 1 };
//         }
//         return item;
//       });
//     },
//   },
// });

// export const {
//   addToBasket,
//   basketTotal,
//   removeFromBasket,
//   increaseItemQuantity,
//   decreaseItemQuantity,
//   removeAllItems
// } = cartSlice.actions;





// export default cartSlice.reducer;





// // import { createSlice } from "@reduxjs/toolkit";

// // const initialState = {
// //   items: [],
// // };

// // export const basketSlice = createSlice({
// //   name: "basket",
// //   initialState,
// //   reducers: {


    
// //     addToBasket: (state, action) => {
// //       state.items = [...state.items, action.payload];
// //     },
// //     removeFromBasket: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);

// //       let newBasket = [...state.items];

// //       if (index >= 0) {
// //         newBasket.splice(index, 1);
// //       } else {
// //         console.warn(`Can't remove product (id: ${action.payload.id}) as it's not in the basket.`);
// //       }

// //       state.items = newBasket;
// //     },
// //     removeAllItems: (state) => {
// //       state.items = [];
// //     },




// //     incrementQuantity: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);
// //       if (index >= 0) {
// //         state.items[index].cartQuantity += 1;
// //       } else {
// //         console.warn(`Can't increment quantity for product (id: ${action.payload.id}) as it's not in the basket.`);
// //       }
// //     },
// //     decrementQuantity: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);
// //       if (index >= 0) {
// //         if (state.items[index].cartQuantity > 1) {
// //           state.items[index].cartQuantity -= 1;
// //         } else {
// //           // If the quantity is 1, remove the item from the basket
// //           state.items.splice(index, 1);
// //         }
// //       } else {
// //         console.warn(`Can't decrement quantity for product (id: ${action.payload.id}) as it's not in the basket.`);
// //       }
// //     },
// //   },
// // });

// // export const { addToBasket, removeFromBasket, removeAllItems, incrementQuantity, decrementQuantity } = basketSlice.actions;

// // export const selectBasketItems = (state) => state.basket.items;

// // export const selectBasketItemsWithId = (state, id) =>
// //   state.basket.items.filter((item) => item.id === id);

// // export const selectBasketTotal = (state) =>
// //   state.basket.items.reduce((total, item) => (total += item.price), 0);

// // export default basketSlice.reducer;


// // import { createSlice } from "@reduxjs/toolkit";

// // const initialState = {
// //   items: [],
// // };

// // export const basketSlice = createSlice({
// //   name: "basket",
// //   initialState,
// //   reducers: {
// //       addToBasket: (state, action) => {
// //         state.items = [...state.items, action.payload];
// //       },



// //     removeFromBasket: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);

// //       let newBasket = [...state.items];

// //       if (index >= 0) {
// //         newBasket.splice(index, 1);
// //       } else {
// //         console.warn(`Can't remove product (id: ${action.payload.id}) as it's not in the basket.`);
// //       }

// //       state.items = newBasket;
// //     },

// //     removeAllItems: (state) => {
// //       state.items = [];
// //     },
// //   },
// // });

// // export const { addToBasket, removeFromBasket, removeAllItems ,incrementQuantity ,decrementQuantity } = basketSlice.actions;

// // export const selectBasketItems = (state) => state.basket.items;

// // export const selectBasketItemsWithId = (state, id) =>
// //   state.basket.items.filter((item) => item.id === id);

// // export const selectBasketTotal = (state) =>

// //   state.basket.items.reduce((total, item) => (total += item.price), 0);

// // export default basketSlice.reducer;


// // import { createSlice } from "@reduxjs/toolkit";

// // const initialState = {
// //   items: [],
// // };

// // export const basketSlice = createSlice({
// //   name: "basket",
// //   initialState,
// //   reducers: {
// //     addToBasket: (state, action) => {
// //       state.items = [...state.items, action.payload];
// //     },

// //     removeFromBasket: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);

// //       let newBasket = [...state.items];

// //       if (index >= 0) {
// //         newBasket.splice(index, 1);
// //       } else {
// //         console.warn(`Can't remove product (id: ${action.payload.id}) as it's not in the basket.`);
// //       }

// //       state.items = newBasket;
// //     },

// //     removeAllItems: (state) => {
// //       state.items = [];
// //     },

// //     incrementItemQuantity: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);

// //       if (index >= 0) {
// //         state.items[index].quantity += 1;
// //       }
// //     },

// //     decrement: (state, action) => {
// //       const index = state.items.findIndex((item) => item.id === action.payload.id);

// //       if (index >= 0) {
// //         if (state.items[index].quantity > 1) {
// //           state.items[index].quantity -= 1;
// //         } else {
// //           state.items.splice(index, 1);
// //         }
// //       }
// //     },
// //   },
// // });

// // export const { addToBasket, removeFromBasket, removeAllItems, incrementItemQuantity, decrement } = basketSlice.actions;

// // export const selectBasketItems = (state) => state.basket.items;

// // export const selectBasketItemsWithId = (state, id) =>
// //   state.basket.items.filter((item) => item.id === id);

// // export const selectBasketTotal = (state) =>
// //   state.basket.items.reduce((total, item) => (total += item.price * item.quantity), 0);

// // export default basketSlice.reducer;


// // export const selectBasketItems = (state) => state.basket.items;

// // export const selectBasketItemsWithId = (state, id) =>
// //   state.basket.items.filter((item) => item.id === id);

// // export const selectBasketTotal = (state) =>
// //   state.basket.items.reduce((total, item) => (total += item.price), 0);

// // export default basketSlice.reducer;




import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  cart: [],

  totalQuantity: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // addToBasket: (state, action) => {
    //   let find = state.cart.findIndex((item) => item.id === action.payload.id);
    //   if (find >= 0) {
    //     state.cart[find].quantity += 1;
    //   } else {
    //     state.cart.push(action.payload);
    //   }
    // },
    addToBasket: (state, action) => {
      // Check if the item with the same id and flavor already exists in the cart
      const existingItem = state.cart.find(
        (item) => item.id === action.payload.id && item.flavour === action.payload.flavour
      );
    
      if (existingItem) {
        // If it exists, increase the quantity of the existing item
        existingItem.quantity += 1;
      } else {
        // If it doesn't exist, add a new item to the cart
        state.cart.push(action.payload);
      }
    },
    

   
//     addToBasket: (state, action) => {
//       state.items = [...state.items, action.payload];
//     },


    basketTotal: (state) => {
      let { totalQuantity, totalPrice } = state.cart.reduce(
        (cartTotal, cartItem) => {
          console.log("carttotal", cartTotal);
          console.log("cartitem", cartItem);
          const { price, quantity } = cartItem;
          console.log(price, quantity);
          const itemTotal = price * quantity;
          cartTotal.totalPrice += itemTotal;
          cartTotal.totalQuantity += quantity;
          return cartTotal;
        },
        {
          totalPrice: 0,
          totalQuantity: 0,
        }
      );
      state.totalPrice = parseInt(totalPrice.toFixed(2));
      state.totalQuantity = totalQuantity;
    },


    // removeFromBasket: (state, action) => {
    //   const { id, flavour } = action.payload;
    //   const existingItemIndex = state.cart.findIndex(
    //     (item) => item.id === id && item.flavour === flavour
    //   );
    
    //   if (existingItemIndex !== -1) {
    //     const existingItem = state.cart[existingItemIndex];
    //     if (existingItem.quantity > 1) {
    //       existingItem.quantity -= 1;
    //     } else {
    //       // If the quantity is 1 or less, remove the item from the cart
    //       state.cart.splice(existingItemIndex, 1);
    //     }
    //   }
    // },



    // removeFromBasket: (state, action) => {
    //   state.cart = state.cart.filter((item) => item.id !== action.payload);
    // },



    // removeFromBasket: (state, action) => {
    //   const { id, flavour } = action.payload;
    //   const existingItemIndex = state.cart.findIndex(
    //     (item) => item.id === id && item.flavour === flavour
    //   );
    
    //   if (existingItemIndex !== -1) {
    //     const existingItem = state.cart[existingItemIndex];
    //     if (existingItem.quantity > 1) {
    //       existingItem.quantity -= 1;
    //       existingItem.quantity -= 1;

    //     } else {
    //       // If the quantity is 1 or less, remove the item from the cart
    //       state.cart.splice(existingItemIndex, 1);
    //     }
    //   }
    // },
    
    removeFromBasket: (state, action) => {
      const { id, flavour } = action.payload;
      const updatedCart = state.cart.filter(
        (item) => !(item.id === id && item.flavour === flavour)
      );
      state.cart = updatedCart;
    },
    
    
    



    removeAllItems: (state) => {
      state.cart = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },



    // increaseItemQuantity: (state, action) => {
    //   state.cart = state.cart.map((item) => {
    //     if (item.id === action.payload) {
    //       return { ...item, quantity: item.quantity + 1 };
    //     }
    //     return item;
    //   });
    // },
    // decreaseItemQuantity: (state, action) => {
    //   state.cart = state.cart.map((item) => {
    //     if (item.id === action.payload) {
    //       return { ...item, quantity: item.quantity - 1 };
    //     }
    //     return item;
    //   });
    // },


    increaseItemQuantity: (state, action) => {
      const { id, flavour } = action.payload;
      const itemToUpdate = state.cart.find(
        (item) => item.id === id && item.flavour === flavour
      );
    
      if (itemToUpdate) {
        itemToUpdate.quantity += 1;
      }
    },
    
    decreaseItemQuantity: (state, action) => {
      const { id, flavour } = action.payload;
      const itemToUpdate = state.cart.find(
        (item) => item.id === id && item.flavour === flavour
      );
    
      if (itemToUpdate && itemToUpdate.quantity > 1) {
        itemToUpdate.quantity -= 1;
      }
    },
      

  },
});

export const {
  addToBasket,
  basketTotal,
  removeFromBasket,
  increaseItemQuantity,
  decreaseItemQuantity,
  removeAllItems
} = cartSlice.actions;





export default cartSlice.reducer;



