import React from 'react'
import { FaRegCommentDots } from 'react-icons/fa'
import ForumPost2 from '../Components/ForumPost/ForumPost2'
import './ForumName.css'

const ForumName = () => {
  return (
   <div className="ForumName">
    <ForumPost2/>
    <div className="ForumNameBody">

    <h1 className="ForumBodyH1">Hello Gurkirat</h1>
        <p className="ForumBodyp">
          What do you think?
        </p>
        <div className="ForumNameBodyCommentBox">
   <div className="ForumNameBodyCommentBoxHeader">
    <h3 className='ForumCreatePostBodyh3'>Comment on this post</h3>
    <input className="ForumCreatePostBodyinput" type="text" />
    <div className="ForumNameBodyCommentBoxBody">
    <button className="ForumNameBodyCommentBoxBodyButton"><FaRegCommentDots/>Comment</button>
   </div>
   </div>

        </div>
        <ForumPost2/>
        <ForumPost2/>
        <ForumPost2/>
        <ForumPost2/>


    </div>
   </div>
  )
}

export default ForumName