// import { configureStore } from "@reduxjs/toolkit";
// import { save, load } from "redux-localstorage-simple";
// import basketReducer from "./Components/Redux/basketSlice";

// const preloadedState = load();

// export const store = configureStore({
//   reducer: {
//     basket: basketReducer,
//   },
//   preloadedState,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()),
// });

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./Components/Redux/basketSlice";
import { save, load } from "redux-localstorage-simple";

const preloadedState = load();

export const store = configureStore({
  reducer: {
    allCart: cartReducer,

  },
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()),
});

export default store;



