import React, { useEffect, useMemo, useState } from "react";
import "./Checkout.css";
import { BiChevronRight } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Currency from "react-currency-formatter";
import { toast } from "react-hot-toast";
import { auth, db } from "../../../Firebase/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { removeAllItems, selectBasketItems } from "../../Redux/basketSlice";
import emailjs from "@emailjs/browser";

const Checkout = () => {
  const dispatch = useDispatch();

  const [coupunshow, setCouponshow] = useState(false);

  const [name, setName] = useState();

  const [phone, setPhone] = useState();
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [house, setHouse] = useState();

  const [userID] = useAuthState(auth);

  const [addressdetails, setAddressdetails] = useState();

  useEffect(() => {
    try {
      getDoc(doc(db, "user", userID?.uid)).then((docSnap) => {
        try {
          if (docSnap.exists()) {
            getDoc(
              doc(
                db,
                "user",
                userID?.uid,
                "Address",
                docSnap.data()?.defaultAddressid
              )
            ).then((docSnap) => {
              if (docSnap.exists()) {
                setAddressdetails(docSnap.data());
              } else {
                console.log("No Document Found");
              }
            });
          } else {
            console.log("No Document Found");
          }
        } catch {}
      });
    } catch {}
  }, [userID?.uid]);

  useEffect(() => {
    if (addressdetails) {
      setName1(addressdetails.name);
      setPhone1(addressdetails.phone);
      setPincode1(addressdetails.pincode);
      setAddress1(addressdetails.address);
      setEmail1(addressdetails.email);
      setCity1(addressdetails.city);
      setState1(addressdetails.state);
      setHouse1(addressdetails.house);
    }
  }, [addressdetails]);

  console.log(addressdetails);

  const [name1, setName1] = useState();

  const [phone1, setPhone1] = useState();
  const [pincode1, setPincode1] = useState();
  const [address1, setAddress1] = useState();
  const [email1, setEmail1] = useState();
  const [city1, setCity1] = useState();
  const [state1, setState1] = useState();
  const [house1, setHouse1] = useState();

  const navigate = useNavigate();

  const { cart, totalQuantity, totalPrice } = useSelector(
    (state) => state.allCart
  );

  console.log(cart[0]?.price);

  const guestsendform = () => {
    if (!email || !name || !pincode || !address || !city || !state || !phone) {
      toast.error("Please Fill All Fields");
    } else {
      addDoc(collection(db, "orders"), {
        ProductsQty: totalQuantity,
        TotalAmount: totalAmount + 100,
        Subtotal: totalPrice,
        DeliveryPrice: 100,
        Name: name,
        House: house,
        coupon: checkcoupon,
        Address: address,
        Pincode: pincode,
        State: state,
        City: city,
        Phone: phone,
        email: email,
        Member: "false",

        createdAt: serverTimestamp(),
      }).then((docRef) => {
        const data = {
          orderId: docRef?.id,
          Member: "No",
          Name: name,
          House: house,
          Address: address,
          Pincode: pincode,
          email: email,
          State: state,
          City: city,
          Phone: phone,
          ProductsQty: totalQuantity,
          TotalAmount: totalAmount + 100,
          Subtotal: totalPrice,
        };

        emailjs.send(
          "service_iovw4qe",
          "template_eosg07a",
          data,
          "94pvZqK99aNyj8F-P"
        );

        for (let i = 0; i < cart.length; i++) {
          addDoc(collection(db, "orders", docRef?.id, "Product"), {
            ProductName: cart[i]?.productname,
            ProductPrice: cart[i]?.price,
            ProductImage: cart[i]?.image,
            ProductQty: cart[i]?.quantity,
            DeliveryPrice: 100,
          });
        }

        toast.success("Order Confirmed");
        dispatch(removeAllItems());
        navigate("/");
        setName("");
        setPhone("");
        setPincode("");
        setAddress("");
        setState("");
        setCity("");
        setEmail("");
      });
    }
  };

  const membersendform = () => {
    if (
      !email1 ||
      !name1 ||
      !pincode1 ||
      !address1 ||
      !city1 ||
      !state1 ||
      !phone1
    ) {
      toast.error("Please Fill All Fields");
    } else {
      addDoc(collection(db, "orders"), {
        ProductsQty: totalQuantity,
        TotalAmount: totalAmount + 100,
        Subtotal: subtotal,
        coupon: checkcoupon,
        DeliveryPrice: 100,
        Name: name1,
        House: house1,
        Address: address1,
        Pincode: pincode1,
        State: state1,
        City: city1,
        Phone: phone1,
        email: email1,
        Member: "True",

        createdAt: serverTimestamp(),
      }).then((docRef) => {
        const data = {
          orderId: docRef?.id,
          Member: "Yes",
          Name: name1,
          UserUid: userID?.uid,
          Address: address1,
          Pincode: pincode1,
          State: state1,
          House: house1,
          email: email1,
          City: city1,
          Phone: phone1,
          Coupon: checkcoupon,
          ProductsQty: totalQuantity,
          TotalAmount: totalAmount + 100,
          Subtotal: subtotal,
        };

        emailjs.send(
          "service_iovw4qe",
          "template_eosg07a",
          data,
          "94pvZqK99aNyj8F-P"
        );

        for (let i = 0; i < cart.length; i++) {
          addDoc(collection(db, "orders", docRef?.id, "Product"), {
            ProductName: cart[i]?.productname,
            ProductPrice: cart[i]?.price,
            ProductImage: cart[i]?.image,
            ProductQty: cart[i]?.quantity,

            DeliveryPrice: 100,
          });

          addDoc(collection(db, "user", userID?.uid, "Orders"), {
            ProductName: cart[i]?.productname,
            ProductPrice: cart[i]?.price,
            ProductImage: cart[i]?.image,
            ProductQty: cart[i]?.quantity,

            DeliveryPrice: 100,
            createdAt: serverTimestamp(),
          });
        }

        toast.success("Order Confirmed");
        dispatch(removeAllItems());
        navigate("/");
        setName1("");
        setPhone1("");
        setPincode1("");
        setAddress1("");
        setState1("");
        setCity1("");
        setEmail1("");
      });
    }
  };

  const [inputcoupon, setinputcoupon] = useState("");
  const [checkcoupon, setcheckcoupon] = useState("");
  const [totalAmount, setTotalAmount] = useState(totalPrice);
  const [subtotal,setSubtotal]=useState(totalPrice);

  const [coupontry, setCoupontry] = useState(false);

  const onClickCoupon = () => {
    setCoupontry(true);

    getDoc(doc(db, "coupon", inputcoupon)).then((docSnap) => {
      if (docSnap.exists()) {
        setTotalAmount(
          totalAmount -
            (parseInt(docSnap.data()?.discount) / 100) * parseInt(totalAmount)
        );
        console.log("Document data:", docSnap.data());
        setcheckcoupon(docSnap.data());
        toast.success("Coupon  added successfully");
      } else {
        setTotalAmount(totalAmount);
        toast.error("Discount code is not valid");
      }
      setinputcoupon("");
      setCouponshow(false);
    });
  };
  // const items = useSelector(selectBasketItems);

  // const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);

  // useMemo(() => {
  //   const groupedItems = items.reduce((results, item) => {
  //     (results[item.id] = results[item.id] || []).push(item);
  //     return results;
  //   }, {});

  //   setGroupedItemsInBasket(groupedItems);
  // }, [items]); // Include 'items' in the dependency array

  // const cartData = useSelector((state) => state.cart);
  // console.log("Cart Data:", cartData);




  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };


  const displayRazorpay = async () => {
    if (!email1 || !name1 || !pincode1 || !address1 || !city1 || !state1 || !phone1) {
      toast.error("Please Fill All Fields");
    } else {
      try {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  
        if (!res) {
          alert('You are offline... Failed to load Razorpay SDK');
        }
  
        const options = {
          key: 'rzp_test_uHaNy8zOYv0KcN',
          currency: 'INR',
          amount: subtotal * 100  ,
          name: 'Protein Cartel',
          description: 'Thanks for purchasing',
          handler: async (response) => {
            try {
              // Check if payment was successful
              if (response.razorpay_payment_id) {
                toast.success('Payment Successfully');
  
                // Add the order to Firestore
                const docRef = await addDoc(collection(db, "orders"), {
                  ProductsQty: totalQuantity,
                  TotalAmount: totalAmount + 100,
                  Subtotal: subtotal,
                  coupon: checkcoupon,
                  DeliveryPrice: 100,
                  Name: name1,
                  House: house1,
                  Address: address1,
                  Pincode: pincode1,
                  State: state1,
                  City: city1,
                  Phone: phone1,
                  email: email1,
                  Member: "True",
                  createdAt: serverTimestamp(),
                });
  
                const data = {
                  orderId: docRef?.id,
                  Member: "Yes",
                  Name: name1,
                  UserUid: userID?.uid,
                  Address: address1,
                  Pincode: pincode1,
                  State: state1,
                  House: house1,
                  email: email1,
                  City: city1,
                  Phone: phone1,
                  Coupon: checkcoupon,
                  ProductsQty: totalQuantity,
                  TotalAmount: totalAmount + 100,
                  Subtotal: subtotal,
                };
  
                // Send email
                await emailjs.send(
                  "service_iovw4qe",
                  "template_eosg07a",
                  data,
                  "94pvZqK99aNyj8F-P"
                );
  
                // Add products to the order and user's orders
                for (let i = 0; i < cart.length; i++) {
                  await addDoc(collection(db, "orders", docRef?.id, "Product"), {
                    ProductName: cart[i]?.productname,
                    ProductPrice: cart[i]?.price,
                    ProductImage: cart[i]?.image,
                    ProductQty: cart[i]?.quantity,
                    DeliveryPrice: 100,
                  });
  
                  await addDoc(collection(db, "user", userID?.uid, "Orders"), {
                    ProductName: cart[i]?.productname,
                    ProductPrice: cart[i]?.price,
                    ProductImage: cart[i]?.image,
                    ProductQty: cart[i]?.quantity,
                    DeliveryPrice: 100,
                    createdAt: serverTimestamp(),
                  });
                }
  
                toast.success("Order Confirmed");
                dispatch(removeAllItems());
                navigate("/");
                setName1("");
                setPhone1("");
                setPincode1("");
                setAddress1("");
                setState1("");
                setCity1("");
                setEmail1("");
              } else {
                // Handle payment failure
                toast.error('Payment Failed');
              }
            } catch (error) {
              toast.error(error.message);
            }
          },
        };
  
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  

  // const displayRazorpay = async () => {
  //   if (
  //     !email1 ||
  //     !name1 ||
  //     !pincode1 ||
  //     !address1 ||
  //     !city1 ||
  //     !state1 ||
  //     !phone1
  //   ) {
  //     toast.error("Please Fill All Fields");
  //   } else {


  //     try {
  //       const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  
  //       if (!res) {
  //         alert('You are offline... Failed to load Razorpay SDK');
  //       }
  
  //       const options = {
  //         key: 'rzp_test_uHaNy8zOYv0KcN',
  //         currency: 'INR',
  //         amount: subtotal*100,
  //         name: 'Protein Cartel',
  //         description: 'Thanks for purchasing',
  //         handler: function (response) {
  //           toast.success('Payment Successfully');
       
  //         },
  //       };
  
  //       const paymentObject = new window.Razorpay(options);
  //       paymentObject.open();
  //     } catch (error) {
  //       toast.error(error.message); 
  //     }





  //     addDoc(collection(db, "orders"), {
  //       ProductsQty: totalQuantity,
  //       TotalAmount: totalAmount + 100,
  //       Subtotal: subtotal,
  //       coupon: checkcoupon,
  //       DeliveryPrice: 100,
  //       Name: name1,
  //       House: house1,
  //       Address: address1,
  //       Pincode: pincode1,
  //       State: state1,
  //       City: city1,
  //       Phone: phone1,
  //       email: email1,
  //       Member: "True",

  //       createdAt: serverTimestamp(),
  //     }).then((docRef) => {
  //       const data = {
  //         orderId: docRef?.id,
  //         Member: "Yes",
  //         Name: name1,
  //         UserUid: userID?.uid,
  //         Address: address1,
  //         Pincode: pincode1,
  //         State: state1,
  //         House: house1,
  //         email: email1,
  //         City: city1,
  //         Phone: phone1,
  //         Coupon: checkcoupon,
  //         ProductsQty: totalQuantity,
  //         TotalAmount: totalAmount + 100,
  //         Subtotal: subtotal,
  //       };

  //       emailjs.send(
  //         "service_iovw4qe",
  //         "template_eosg07a",
  //         data,
  //         "94pvZqK99aNyj8F-P"
  //       );

  //       for (let i = 0; i < cart.length; i++) {
  //         addDoc(collection(db, "orders", docRef?.id, "Product"), {
  //           ProductName: cart[i]?.productname,
  //           ProductPrice: cart[i]?.price,
  //           ProductImage: cart[i]?.image,
  //           ProductQty: cart[i]?.quantity,

  //           DeliveryPrice: 100,
  //         });

  //         addDoc(collection(db, "user", userID?.uid, "Orders"), {
  //           ProductName: cart[i]?.productname,
  //           ProductPrice: cart[i]?.price,
  //           ProductImage: cart[i]?.image,
  //           ProductQty: cart[i]?.quantity,

  //           DeliveryPrice: 100,
  //           createdAt: serverTimestamp(),
  //         });
  //       }

  //       toast.success("Order Confirmed");
  //       dispatch(removeAllItems());
  //       navigate("/");
  //       setName1("");
  //       setPhone1("");
  //       setPincode1("");
  //       setAddress1("");
  //       setState1("");
  //       setCity1("");
  //       setEmail1("");
  //     });
  //   }


  
  // };















  return (
    <div className="check-out-head1">
      <div className="check-out-head">
        <div className="check-uppr ">
          <Link to={"/"}>
            <h1 className="check-h1">HomePage</h1>
          </Link>
          <BiChevronRight color="white" size={25} />
          <h1 className="check-h2">Checkout</h1>
        </div>

        {checkcoupon ? (
          <></>
        ) : (
          <>
            <div className="coupon-checkout">
              <p onClick={() => setCouponshow(!coupunshow)}>
                Have a coupon?
                <span style={{ color: "#f65000", cursor: "pointer" }}>
                  Click here to enter your code
                </span>
              </p>

              {coupunshow && (
                <>
                  <div className="coupon-sec-2 fadeInDown-check">
                    <p>If you have a coupon code, please apply it below.</p>
                    <div className="coupon-section-1">
                      <input
                        className="coupon-check-input"
                        type="Coupon code"
                        name=""
                        id=""
                        onChange={(e) => setinputcoupon(e.target.value)}
                        value={inputcoupon}
                      />
                      {coupontry ? (
                        <>
                          <button className="apply-cc-check">
                            Apply coupon
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={onClickCoupon}
                            className="apply-cc-check"
                          >
                            Apply coupon
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <div className="check-out-head-dv">
          <div className="check-out-left-h">
            <div className="left-head-c">
              <h1 className="left-h-h1">Address Details</h1>
            </div>
            {userID ? (
              <>
                <form
                  // onSubmit={handleSubmit}
                  className="form-container"
                >
                  <div className="check-form-d1">
                    <div className="form-group">
                      <label className="check-label">
                        Name<span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        id="name"
                        name="name"
                        onChange={(e) => setName1(e.target.value)}
                        value={name1}
                        // onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="check-label">
                        Email <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="email"
                        id="email"
                        name="email"
                        onChange={(e) => setEmail1(e.target.value)}
                        value={email1}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="check-label">
                      Contact No <span style={{ color: "#f65000" }}>*</span>
                    </label>
                    <input
                      className="input-check"
                      type="text"
                      onChange={(e) => setPhone1(e.target.value)}
                      value={phone1}
                      required
                    />
                  </div>

                  <div className="check-form-d1">
                    <div className="form-group">
                      <label className="check-label">
                        Address <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setAddress1(e.target.value)}
                        value={address1}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="check-label">
                        House/Flat No{" "}
                        <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setHouse1(e.target.value)}
                        value={house1}
                        required
                      />
                    </div>
                  </div>
                  <div className="check-form-d1">
                    <div className="form-group">
                      <label className="check-label">
                        Town/City <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setCity1(e.target.value)}
                        value={city1}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="check-label">
                        Pincode <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setPincode1(e.target.value)}
                        value={pincode1}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="check-label">
                      State <span style={{ color: "#f65000" }}>*</span>
                    </label>
                    <input
                      className="input-check"
                      type="text"
                      onChange={(e) => setState1(e.target.value)}
                      value={state1}
                      required
                    />
                  </div>
                </form>
              </>
            ) : (
              <>
                <form
                  // onSubmit={handleSubmit}
                  className="form-container"
                >
                  <div className="check-form-d1">
                    <div className="form-group">
                      <label className="check-label">
                        Name<span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        id="name"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        // onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="check-label">
                        Email <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="email"
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="check-label">
                      Contact No <span style={{ color: "#f65000" }}>*</span>
                    </label>
                    <input
                      className="input-check"
                      type="text"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      required
                    />
                  </div>
                  <div className="check-form-d1">
                    <div className="form-group">
                      <label className="check-label">
                        Address <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label className="check-label">
                        House/Flat No{" "}
                        <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setHouse(e.target.value)}
                        value={house}
                        required
                      />
                    </div>
                  </div>

                  <div className="check-form-d1">
                    <div className="form-group">
                      <label className="check-label">
                        Town/City <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="check-label">
                        Pincode <span style={{ color: "#f65000" }}>*</span>
                      </label>
                      <input
                        className="input-check"
                        type="text"
                        onChange={(e) => setPincode(e.target.value)}
                        value={pincode}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="check-label">
                      State <span style={{ color: "#f65000" }}>*</span>
                    </label>
                    <input
                      className="input-check"
                      type="text"
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                      required
                    />
                  </div>
                </form>
              </>
            )}
          </div>
          <div className="check-out-right-h">
            <div className=" left-bb-check">
              <h1 className="left-h-h1">Your Order</h1>
              <div className="content-right-hcheck">
                <div className="rh-check-con1">
                  <h1>Product</h1>
                  <h1>Subtotal</h1>
                </div>
                {/* {Object.entries(groupedItemsInBasket).map(([key, items]) => (
                  <div key={key}>
                    <div className="rh-check-con2">
                      <h1 className="rh-ch-h1">
                        {items[0]?.productname} x {items?.length}
                      </h1>
                      <h1>
                        <Currency quantity={items[0]?.price} currency="INR" />
                      </h1>
                    </div>
                  </div>
                ))} */}

                {cart?.map((item) => (
                  <div>
                    <div className="rh-check-con2">
                      <h1 className="rh-ch-h1">
                        {item.productname} x {item.quantity}

                        {item.flavour}
                      </h1>
                      <h1>
                        <Currency
                          quantity={item.price * item.quantity}
                          currency="INR"
                        />
                      </h1>
                    </div>
                  </div>
                ))}

                {checkcoupon && (
                  <div className="rh-check-con1">
                    <h1>Coupon Discount</h1>
                    <h1 className="rh-ch-h2">{checkcoupon?.discount}%</h1>
                  </div>
                )}

                <div className="rh-check-con1">
                  <h1>Delivery</h1>
                  <h1 className="rh-ch-h2">
                    <Currency quantity={100} currency="INR" />
                  </h1>
                </div>
                <div className="rh-check-con1">
                  <h1>Subtotal</h1>
                  <h1 className="rh-ch-h2">
                    <Currency quantity={totalPrice} currency="INR" />
                  </h1>
                </div>
                <div className="rh-check-con3">
                  <h1>Total</h1>
                  <h1 className="rh-ch-h2">
                    <Currency quantity={totalAmount + 100} currency="INR" />
                  </h1>
                </div>
              </div>

              {userID ? (
                <>
                  <div className="check-btn-1">
                    <button 
                    
                    onClick={() => displayRazorpay()}
                    >
                      Place Order
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="check-btn-1">
                    <button
                     onClick={() => displayRazorpay()}
                     >Place Order</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
