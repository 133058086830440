import React from "react";
import "./Terms.css";

const Refund = () => {
  return (
    <div className="bg-conn" style={{ height: "100vh" }}>
      <h1>Refund and Cancellation Policy </h1>
      <div className="ngh-1">
        <div className="ngf-com1">
                    <p>
            At Protein Cartel, we are committed to providing our customers with
            the best possible experience. If for any reason you are not
            satisfied with your purchase, please review our Refund and
            Cancellation Policy below.
          </p>
          <h1>Refunds</h1>
          <p>
            We offer 3-4 day money-back guarantee on all of our products. If you
            are not completely satisfied with your purchase, you may return the
            product for a full refund within 4 days of the purchase date. To be
            eligible for a refund, the product must be in its original condition
            and packaging.
          </p>
          <p>
            To initiate a refund, please contact our customer service team at
            refunds@proteincartel.com. We will provide you with instructions on
            how to return the product. You will be responsible for paying for
            the return shipping costs.
          </p>

          <p>
            Once we receive your returned product, we will inspect it to ensure
            that it meets our return policy requirements. If your return is
            approved, we will issue a refund to your original payment method
            within 5-7 business days.
          </p>
          <h1>Cancellations</h1>
          <p>
            If you wish to cancel an order, please contact us as soon as
            possible at orders@proteincartel.com. We will make every effort to
            cancel the order before it is shipped.
          </p>
          <p>
            If the order has already been shipped, you may still return the
            product for a refund as outlined in our Refund Policy above.
          </p>
          <h1>Exceptions</h1>

          <p>
            There are certain situations where we may not be able to offer a
            refund or cancellation. These include:
          </p>
          <p>
            Products that have been opened or used Products that have been
            damaged by the customer Products that have been customized or
            personalized Products that were purchased through a third-party
            retailer In these situations, we may offer a partial refund or store
            credit at our discretion.
          </p>

          <h1>Contact Us</h1>
          <p>
            If you have any questions or concerns about our Refund and
            Cancellation Policy, please contact our customer service team at
            info@proteincartel.com. We will be happy to assist you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Refund;
