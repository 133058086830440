import { useState } from "react";

// Centralizes modal control
const useModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => {
    setModalOpen(false)
    document.body.style.overflow = 'unset';
  };
  const open = () => setModalOpen(true);

  return { modalOpen, close, open };
};

export default useModal;
