import React from 'react'
import { Link } from 'react-router-dom'
import TrendingCard from './TrendingCard/TrendingCard'
import VlogCard from './Vlog Card/VlogCard'
import './Vlog.css'
import VlogPlaylist from './VlogPlaylist/VlogPlaylist'

const Vlog = () => {
  return (
   <div className="Vlog">
     <div style={{ marginBottom: "40px" }} className="ForumHeader">
        <h1 style={{textAlign : 'center'}} className="ForumHeaderH1">Vlogs!</h1>
      </div>
    <div className="VlogPlaylistt">
    
      <div className="VlogPlaylisttHeader">
      <div className="VlogPlaylisttHeader1">
      <h1>Latest Vlogs</h1>
      </div>
      </div>
    <VlogPlaylist/>
    </div>

<div className="VlogTrendingVideos">
<h1 className='VlogCardListh1'>Trending Videos</h1>
<div className="VlogCardListLine">
  <div className="VlogCardListLine2">
    <div className="VlogCardListLine3"></div>
  </div>
</div>
<div className="TrendingFlex">
  <Link style={{textDecoration: 'none'}} to={'/VlogName'}>

<TrendingCard/>
  </Link>
  <Link style={{textDecoration: 'none'}} to={'/VlogName'}>

<TrendingCard/>
  </Link>
  <Link style={{textDecoration: 'none'}} to={'/VlogName'}>

<TrendingCard/>
  </Link>

</div>

</div>

    <div className="VlogCardList">
<h1 className='VlogCardListh1'>All Vlogs</h1>
<div className="VlogCardListLine">
  <div className="VlogCardListLine2">
    <div className="VlogCardListLine3"></div>
  </div>
</div>
    <VlogCard/>
    <VlogCard/>
    <VlogCard/>
    <VlogCard/>

    </div>
   </div>
  )
}

export default Vlog